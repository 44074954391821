<template>
    <div class="edit-time-range" ref="modal">
        <div v-if="displayCreate">
            <div class="create-container">
                <p class="advertisement">
                    {{ $t("vigik.modale.createSchedule.limitAtFiveSlots") }}
                </p>
                <p>
                    {{ $t("link_words.on") }}
                </p>
                <div class="input-days-create-container">
                    <div
                        v-for="day in days"
                        :key="`day-${day.value}`"
                        class="input-days-create-wrapper"
                    >
                        <BasicButton
                            classStyle="btn-checkbox"
                            :color="
                                selectedDays.includes(day.value)
                                    ? 'blue'
                                    : 'white'
                            "
                            :disabled="isMaxPerDay(day.value)"
                            @click="checkDay(day.value)"
                        >
                            {{ $t(`${day.name}`).slice(0, 1) }}
                        </BasicButton>
                    </div>
                </div>
                <div class="checkbox-all-days-wrapper">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        v-model="selectAllDay"
                        @change="startEndAllDay"
                    />
                    <p>{{ $t("vigik.schedule.selectAllDay") }}</p>
                </div>
            </div>
        </div>
        <div>
            <div class="start-timer-wrapper">
                <label class="form-check-label" for="startHour">
                    {{ $t("link_words.from") }}
                </label>
                <BasicInput
                    v-model="startValue"
                    type="time"
                    name="startValue"
                    class="input-timer"
                    @update:modelValue="startValue($event)"
                />
            </div>
            <div class="error" v-if="!validStart && displayError">
                {{ $t("alerts.form.invalid_hour") }}
            </div>
        </div>
        <div>
            <div class="end-timer-wrapper">
                <label class="form-check-label" for="endHour">
                    {{ $t("link_words.to") }}
                </label>
                <BasicInput
                    v-model="endValue"
                    type="time"
                    name="startValue"
                    class="input-timer"
                    @update:modelValue="endValue($event)"
                />
            </div>
            <div class="error" v-if="!validEnd && displayError && errorContent">
                {{ errorContent }}
            </div>
            <div v-if="isAlreadyCovered && !validEnd" class="error">
                <p>{{ $t("vigik.modale.createSchedule.existingSchedules") }}</p>
            </div>
        </div>
        <div v-if="isAlreadyCovered && validEnd" class="error">
            <p>{{ $t("vigik.modale.createSchedule.existingSchedules") }}</p>
        </div>
        <div v-if="displayCreate && maxDayError" class="error">
            <p>{{ $t("alerts.form.max_day") }}</p>
        </div>
        <div class="actions">
            <basic-button
                v-if="displayCreate"
                class="valid"
                @click="createValue"
                :disabled="
                    !isContainsInteger ||
                    !validEnd ||
                    !validStart ||
                    isAlreadyCovered
                "
            >
                {{ $t("button.register") }}
            </basic-button>
            <basic-button
                v-else
                class="valid"
                @click="editValue"
                :disabled="
                    !validEnd ||
                    !validStart ||
                    isAlreadyCovered ||
                    (isEqual(this.formatHour(start), startValue) &&
                        isEqual(this.formatHour(end), endValue))
                "
            >
                {{ $t("button.register") }}
            </basic-button>
            <basic-button
                class="delete"
                v-if="displayDelete"
                @click="deleteValue"
                color="blue"
            >
                {{ $t("button.delete") }}
            </basic-button>
            <basic-button class="invalid" v-else @click="close" color="blue">
                {{ $t("button.cancel") }}
            </basic-button>
        </div>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicInput from "@/components/basic/BasicInput.vue"

export default {
    name: "EditTimeRange",
    components: { BasicButton, BasicInput },
    props: {
        start: {
            type: String,
            required: false,
        },
        end: {
            type: String,
            required: false,
        },
        day: {
            type: Number,
            required: false,
        },
        days: {
            type: Array,
            required: true,
        },
        differentDay: {
            type: Array,
            required: true,
        },
        displayDelete: {
            type: Boolean,
            required: false,
            default: false,
        },
        displayCreate: {
            type: Boolean,
            required: false,
            default: false,
        },
        deleteTimeRange: {
            type: Function,
            required: false,
        },
        editTimeRange: {
            type: Function,
            required: false,
        },
        createTimeRange: {
            type: Function,
            required: false,
        },
        periodIsAlreadyCovered: {
            type: Function,
            required: false,
        },
    },
    data() {
        return {
            daySelected: null,
            startValue: null,
            endValue: null,
            validStart: false,
            validEnd: false,
            validDate: false,
            displayError: false,
            errorContent: null,
            maxDayError: false,
            selectedDays: [],
            selectAllDay: false,
        }
    },

    watch: {
        startValue(newValue) {
            if (this.selectAllDay && this.startValue !== "00:00") {
                this.selectAllDay = false
            }
            this.displayError = true
            this.validStart = this.isValidValue(newValue)
        },
        endValue(newValue) {
            if (this.selectAllDay && this.endValue !== "23:59") {
                this.selectAllDay = false
            }
            this.displayError = true
            this.validEnd = this.isValidValue(newValue)
        },
    },
    created() {
        if (this.start && this.end) {
            this.startValue = this.formatHour(this.start)
            this.endValue = this.formatHour(this.end)
        }
        if (this.day || this.day === 0) {
            this.daySelected = this.day
            this.selectedDays[this.day] = true
        }
    },
    mounted() {
        this.deleteTitle()
    },
    computed: {
        isContainsInteger() {
            return this.selectedDays.some((element) =>
                Number.isInteger(element)
            )
        },
        isAlreadyCovered() {
            const period = {
                start: this.startValue,
                end: this.endValue,
                selectedDays: this.selectedDays,
            }
            return this.periodIsAlreadyCovered(period)
        },
    },

    methods: {
        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
        isEqual(value1, value2) {
            if (value1 === value2) {
                return true
            }
            return false
        },
        checkDay(day) {
            if (this.selectedDays[day] === day) {
                delete this.selectedDays[day]
            } else {
                this.selectedDays[day] = day
            }
        },
        formatHour(hour) {
            let formatedValue = ""
            let time = hour.split(":")
            if (parseInt(time[0]) > 9) {
                formatedValue = parseInt(time[0])
            } else {
                formatedValue = `0${parseInt(time[0])}`
            }
            if (parseInt(time[1]) > 9) {
                formatedValue += `:${parseInt(time[1])}`
            } else {
                formatedValue += `:0${parseInt(time[1])}`
            }
            return formatedValue
        },
        isValidValue(newValue) {
            const splitValue = newValue.split(":")

            if (splitValue.length !== 2) {
                return false
            } else if (splitValue[0].length > 2 && splitValue[1].length > 2) {
                return false
            } else if (
                isNaN(parseInt(splitValue[0])) ||
                isNaN(parseInt(splitValue[1]))
            ) {
                return false
            }

            if (this.startValue && this.endValue) {
                const startValue = this.startValue.split(":")
                const endValue = this.endValue.split(":")
                if (parseInt(startValue[0]) > parseInt(endValue[0])) {
                    this.errorContent = this.$t("alerts.form.invalid_hour")
                    return false
                } else if (
                    parseInt(startValue[0]) === parseInt(endValue[0]) &&
                    parseInt(startValue[1]) >= parseInt(endValue[1])
                ) {
                    this.errorContent = this.$t("alerts.form.invalid_hour")
                    return false
                }
            }

            if (this.startValue && this.endValue) {
                const startValue = parseInt(this.startValue.replace(":", ""))
                const endValue = parseInt(this.endValue.replace(":", ""))

                if (endValue - startValue < 15) {
                    this.errorContent = this.$t("vigik.form.minimalTime")
                    return false
                }
            }

            this.errorContent = null
            this.validEnd = true
            this.validStart = true
            return true
        },
        close() {
            this.closeModal()
        },
        deleteValue() {
            this.deleteTimeRange()
        },
        isMaxPerDay(value) {
            if (!this.differentDay[value]) {
                return false
            } else {
                return this.differentDay[value].length >= 5
            }
        },
        editValue() {
            this.editTimeRange({
                start: this.startValue,
                end: this.endValue,
            })
        },
        createValue() {
            this.createTimeRange({
                start: this.startValue,
                end: this.endValue,
                selectedDays: this.selectedDays,
            })
        },
        startEndAllDay() {
            if (this.selectAllDay) {
                this.startValue = "00:00"
                this.endValue = "23:59"
            } else {
                this.startValue = ""
                this.endValue = ""
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.edit-time-range {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 1em;
    font-size: $normal;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .create-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1em;

            .advertisement {
                text-align: center;
            }

            p {
                margin: 0;
            }

            .input-days-create-container {
                display: flex;
                flex-direction: row;
                gap: 0.2em;
                justify-content: center;

                .input-days-create-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }

            .checkbox-all-days-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5em;
                width: max-content;

                input {
                    margin: 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                p {
                    margin: 0;
                    font-size: $small;
                    font-family: $font-avenir-medium;
                }
            }
        }

        .start-timer-wrapper,
        .end-timer-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-top: 0;

            label {
                display: flex;
                flex-direction: row;
                justify-content: start;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;
                font-size: $small;
                font-family: $font-avenir-medium;
            }

            .input-timer {
                width: 80%;
            }
        }
        &.error {
            color: $pink;
            font-family: $font-avenir-medium;
            font-size: $small;
        }
        label {
            width: 20%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            gap: 5px;
            div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;
                width: 100%;
                height: 100%;
            }
            label {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;
                width: fit-content;
                margin-top: -20px;
            }
        }
    }
    .actions {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 1em;

        button {
            padding: 0 1.5em;
        }
    }

    .error {
        color: $pink;
        font-family: $font-avenir-medium;
        font-size: $small;
    }
}
</style>
