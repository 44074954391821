import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import {
    createAccessTypeFormat,
    residencesFormat,
    residenceSummaryByIdFormat,
    residenceFormat,
    rgpdFormat,
    cageRgpdFormat,
    cagesFormat,
} from "../formats"

/**
 * Returns a list of residences
 */
export function getResidences(data) {
    data = formatData(residencesFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/residence", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns a specific residence
 */
export function getResidenceById(residenceId) {
    return httpClient
        .get("/residence/" + residenceId)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns a specific residence and its global related informations
 */
export function getResidenceSummaryById(residenceId, values) {
    const data = formatData(residenceSummaryByIdFormat, values)
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/residence/" + residenceId + "/summary", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns the update status of a residence
 */
export function getResidenceStatusById(residenceId) {
    return httpClient
        .get("/residence/" + residenceId + "/status")
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns the subbaccounts of a residence
 */
export function getResidenceSubaccountsById(residenceId, data = null) {
    let params
    if (data !== null) {
        params = encodeRequestParameters(data)
    }
    return httpClient
        .get("/residence/" + residenceId + "/manage", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function createResidence(data) {
    data = formatData(residenceFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/residence`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function updateResidence(id, data) {
    data = formatData(residenceFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/residence/${id}`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function deleteResidence(residenceId) {
    return httpClient
        .delete(`/residence/${residenceId}`)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function updateRgpd(id, data) {
    data = formatData(rgpdFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/residence/${id}/rgpd`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getCages(data) {
    data = formatData(cagesFormat, data)
    const params = encodeRequestParameters(data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .get(`/cage`, { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function saveCageRGPD(data) {
    data = formatData(cageRgpdFormat, data)
    if (data === false) {
        throw "500"
    }
    return httpClient
        .post(`/cage/rgpd`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getAuthorisation(data) {
    let params
    if (data !== null) {
        params = encodeRequestParameters(data)
    }
    return httpClient
        .get(`/autorisation`, data)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
