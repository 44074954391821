import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import { accessFormat, registerFormat } from "../formats"
import { passwordSecurityFormat } from "../formats"

/**
 * Register a new account
 */
export function register(data) {
    data = formatData(registerFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .post("/auth/register", params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Register a new account
 */
export function getPasswordSecurityLevel(data) {
    data = formatData(passwordSecurityFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .post("/password/security", params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getNotifications() {
    return httpClient
        .get("/notifications?messages=unread")
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
