<template>
    <div v-if="showModal" id="modal-loader">
        <div class="modal" id="modal" @click="outerClose($event)">
            <div class="content" :class="customClass">
                <div @click="closeModal" class="cross-close">
                    <i class="fa fa-times"></i>
                </div>
                <div class="title">
                    <span v-if="title" :class="chooseSize(type)">
                        {{
                            $t(`global.modal.${title}`, {
                                name: props.name,
                                count: modifiedList.length,
                            })
                        }}
                    </span>
                </div>
                <div class="component">
                    <component :is="type" :key="type" v-bind="props" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditTimeRange from "@/components/Modal/EditTimeRange"
import CopieDayTimeRange from "@/components/Modal/CopieDayTimeRange"
import Validation from "@/components/Modal/Validation"
import Rgpd from "@/components/Modal/RGPD"
import Alert from "@/components/Modal/Alert"
import ProvidersStatus from "@/components/Modal/ProvidersStatus"
import DoubleAuth from "@/components/Modal/DoubleAuth.vue"

export default {
    name: "ModalLoader",
    components: {
        EditTimeRange,
        CopieDayTimeRange,
        Validation,
        Alert,
        ProvidersStatus,
        Rgpd,
        DoubleAuth,
    },
    emits: ["modal", "close-modal"],
    data() {
        return {
            showModal: false,
            title: "",
            type: "",
            customClass: "",
            props: {},
        }
    },
    computed: {
        modifiedList() {
            return this.$store.getters["updateProvidersStatus/modifiedList"]
        },
    },
    created() {
        this.emitter.on("modal", (state) => {
            this.setData(state)
        })
        this.emitter.on("close-modal", () => {
            this.close()
        })
    },
    methods: {
        chooseSize(type) {
            switch (type) {
                case "alert":
                    return "alert-class"
                default:
                    return ""
            }
        },
        setData(state) {
            this.type = state.type
            this.title = state.title
            this.props = state.props
            this.customClass = state.customClass
            this.showModal = true
        },
        closeModal() {
            // this.showModal = false
            this.emitter.emit("close-modal")
        },
        close() {
            this.showModal = false
        },
        outerClose(e) {
            if (e.target.id === "modal") {
                this.emitter.emit("close-modal")
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

#modal-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    .modal {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        .content {
            background-color: $white;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            gap: 1em;
            .title {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: flex-start;
                align-items: center;
                font-size: $normal;
                width: 100%;
                font-family: $font_avenir_heavy;
                padding-top: 1em;

                .alert-class {
                    font-size: $normal;
                    text-align: center;
                }
            }
        }
        .cross-close {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 15px;
            color: $blue-neutral;
            font-size: $big;
        }
        .divider {
            width: 90%;
            height: 2px;
            background-color: $orange-neutral;
        }
        .component {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: flex-start;
            align-items: center;
            width: 100%;
        }
        .small {
            max-width: 20%;
            min-width: 380px;
            display: flex;
            justify-content: center;

            @media all and (max-width: 768px) {
                min-width: 300px;
            }
        }
        .medium {
            width: 50%;
            min-width: 950px;
            @media (max-width: 480px) {
                width: calc(100vw - 40px);
                min-width: unset;
                position: absolute;
                top: 20px;
                left: 20px;
            }
        }
        .big {
            width: 80%;
            @media (max-width: 480px) {
                width: calc(100vw - 40px);
                min-width: unset;
                position: absolute;
                top: 20px;
                left: 20px;
            }
        }
        .invisible {
            width: min-content;
            height: min-content;
            background-color: unset;
            border: unset;
            box-shadow: unset;
            color: $white;
        }
    }
}
</style>
