<template>
    <div class="status-container" ref="modal">
        <div class="details-wrapper" v-if="activitiesInfos.length === 0">
            <div
                v-for="provider in modifiedProviders"
                :key="provider"
                class="providers-infos-wrapper"
            >
                <div class="left-part">
                    <p>{{ provider.company }}</p>
                    <div class="status">
                        <div class="pin" :class="choosePin(provider)"></div>
                        <p>{{ chooseLabelLevel(provider) }}</p>
                    </div>
                </div>
                <div
                    class="right-part"
                    @click="resetProviderLevelFromComponent(provider.id)"
                >
                    <i class="fa fa-times"></i>
                </div>
            </div>
        </div>
        <div v-if="activitiesInfos.length > 0" class="data-container">
            <div
                v-for="provider in activitiesInfos"
                :key="provider"
                class="data-wrapper"
            >
                <p>{{ provider.label }}</p>
                <BasicChip
                    :background="chooseColor(provider)"
                    :text="chooseText(provider)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import BasicChip from "../basic/BasicChip.vue"

export default {
    name: "ProvidersStatus",
    components: {
        BasicChip,
    },
    props: {
        selected: {
            type: Array,
            default: () => [],
        },
        deleteChangement: {
            type: Function,
            default: () => {},
        },
        activitiesInfos: {
            type: Array,
            default: () => [],
        },
    },
    emits: ["delete"],
    computed: {
        ...mapGetters({
            modifiedProviders: "updateProvidersStatus/modifiedList",
        }),
    },
    mounted() {
        this.deleteTitle()
    },
    methods: {
        ...mapActions({
            resetProviderLevel: "updateProvidersStatus/resetProviderLevel",
        }),
        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
        resetProviderLevelFromComponent(providerId) {
            this.resetProviderLevel(providerId)
            // if it was last modified provider, close modal
            if (this.modifiedProviders.length === 0) this.closeModal()
        },
        chooseColor(provider) {
            let color

            switch (provider.allow) {
                case 0:
                    color = "dark-blue"
                    break
                case 1:
                    color = "blue-powder"
                    break
            }
            return color
        },
        chooseText(provider) {
            let text
            switch (provider.allow) {
                case 0:
                    text = this.$t("global.modal.providersStatus.noAuthorized")
                    break
                case 1:
                    text = this.$t("global.modal.providersStatus.authorized")
                    break
            }
            return text
        },
        choosePin(provider) {
            let pin
            switch (provider.level) {
                case "WHITELIST":
                    pin = "whitelist-pin"
                    break
                case "BLACKLIST":
                    pin = "blacklist-pin"
                    break
                case "SELECTED":
                    pin = "selected-pin"
                    break
            }

            return pin
        },

        chooseLabelLevel(provider) {
            let labelLevel
            switch (provider.level) {
                case "WHITELIST":
                    labelLevel = this.$t("vigik.global.whitelisted", {
                        count: 0,
                    })
                    break
                case "BLACKLIST":
                    labelLevel = this.$t("vigik.global.blacklisted", {
                        count: 0,
                    })
                    break
                case "SELECTED":
                    labelLevel = this.$t("vigik.global.default")
                    break
            }

            return labelLevel
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";

.status-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
    max-height: 180px;
    overflow: auto;
    h1 {
        margin: 0;
        font-size: $normal;
        font-family: $font-avenir-heavy;
    }

    .details-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
        .providers-infos-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .left-part {
                display: flex;
                flex-direction: column;
                p {
                    margin: 0;
                    font-size: $normal;
                    font-family: $font-avenir-medium;
                }

                .status {
                    display: flex;
                    align-items: center;
                    gap: 0.5em;
                    .pin {
                        width: 15px;
                        height: 15px;
                        border-radius: 10px;
                    }

                    .selected-pin {
                        background-color: $blue-teal;
                    }

                    .whitelist-pin {
                        background-color: $orange-neutral;
                    }

                    .blacklist-pin {
                        background-color: $blue-neutral;
                    }

                    p {
                        margin: 0;
                        font-size: $normal;
                    }
                }
            }

            .right-part {
                i {
                    cursor: pointer;
                    font-size: $normal;
                    padding: 0.5em;
                }
            }
        }
    }

    .data-container {
        display: flex;
        flex-direction: column;
        gap: 1em;

        .data-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
            }
        }
    }
}
</style>
