// initial state
const state = () => ({
    errorMessage: "",
})

// getters
const getters = {
    getErrorMessage: (state) => {
        return state.errorMessage
    },
}

// actions
const actions = {
    setErrorMessage: ({ commit }, errorMessage) => {
        commit("SET_ERROR_MESSAGE", errorMessage)
    },
}

// mutations
const mutations = {
    SET_ERROR_MESSAGE: (state, errorMessage) => {
        state.errorMessage = errorMessage
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
