<template>
    <router-link v-if="link != null" :to="link">
        <button
            type="button"
            class="btn-style linked"
            :class="['btn-' + color, { 'btn-grey': disabled }]"
            :disabled="disabled"
        >
            <slot></slot>
        </button>
    </router-link>
    <button
        type="button"
        v-else
        :class="['btn-' + color, { 'btn-grey': disabled }, classStyle]"
        :disabled="disabled"
    >
        <slot></slot>
        <span v-if="badgeNumber > 0" class="button-badge">
            {{ badgeNumber }}
        </span>
    </button>
</template>

<script>
export default {
    name: "Button",
    props: {
        link: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: "orange",
        },
        badgeNumber: {
            type: Number,
            default: null,
        },
        classStyle: {
            type: String,
            default: "btn-style",
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.btn-style {
    text-align: center;
    border-radius: 10px;
    padding: 11px 16px;
    font-family: $font_avenir_heavy;
    font-size: $normal;
    min-width: 30px;
    min-height: 44px;
    transition: background-color 200ms, color 200ms, border-color 200ms;

    i {
        vertical-align: bottom;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        5% {
            transform: scale(1.4);
        }
        12% {
            transform: scale(0.9);
        }
        19% {
            transform: scale(1.2);
        }
        25% {
            transform: scale(1);
        }
        100% {
            transform: scale(1);
        }
    }

    .button-badge {
        position: absolute;
        background-color: $orange-neutral;
        border-radius: 30px !important;
        padding: 5px 10px 5px 10px;
        top: 10px;
        color: $white;
        animation: pulse 6s infinite;
    }
}

.linked {
    width: 100%;
    height: 100%;
}

.btn-checkbox {
    width: 2.5em;
    height: 2.5em;
    border-radius: 20px;
    font-family: $font-avenir-black;
    font-size: $small;

    &.btn-white {
        background-color: $white;
        border: $blue-neutral 1px solid;
        color: $blue-neutral;
        transition: all 0.2s;

        &:hover {
            background-color: $white;
            border: $blue-neutral 1px solid;
            color: $blue-neutral;
            transition: all 0.2s;
        }
    }

    &.btn-blue {
        background-color: $blue-bright;
        border: $blue-bright 1px solid;
        color: $white;
        transition: all 0.2s;

        &:hover {
            background-color: $blue-bright;
            border: $blue-bright 1px solid;
            color: $white;
            transition: all 0.2s;
        }
    }

    &.btn-grey {
        background-color: $white;
        border: $grey-dark 1px solid;
        color: $grey-dark;

        &:hover {
            background-color: $white;
            border: $grey-dark 1px solid;
            color: $grey-dark;
        }
    }
}

.btn-style:hover {
    .button-badge {
        color: $white;
    }
}

.btn-style:focus {
    outline: none;
}

.btn-orange {
    background-color: $orange-neutral;
    color: $white;
    border: none;
}

.btn-orange:hover {
    background-color: $orange-light;
}

.btn-blue {
    background-color: $blue-neutral;
    color: $white;
    border: none;
}

.btn-blue:hover {
    background-color: $blue-white;
}

.btn-blue-neutral {
    background-color: $blue-neutral;
    color: $white;
    border: none;
}

.btn-blue-neutral:hover {
    background-color: $blue-light;
}

.btn-white {
    background-color: $white;
    border: 1px solid $grey-neutral;
    color: $grey-dark;
}

.btn-white:hover {
    background-color: $grey-light;
    color: $orange-neutral;
    border-color: $grey-neutral;
}

.btn-grey {
    background-color: $grey-darker;
    border: none;
    color: $white;

    &:hover {
        background-color: $grey-darker;
        cursor: default;
        color: $white;
    }
}

.btn-transparent {
    background-color: #0000002e;
    color: $white;
    border: none;
}

.btn-transparent:hover {
    background-color: #00000072;
}

.btn-unset {
    background-color: unset;
    border: none;
}

.btn-unset:hover {
    background-color: unset;
}

.btn-grey-light {
    background-color: $grey-light;
    border: none;
    color: $blue-neutral;
}
</style>
