<template>
    <div class="private-layout">
        <navigation-bar
            :isOpenMobile="isOpenMobile"
            @setOpenMobile="setOpenMobile"
        ></navigation-bar>
        <div class="content-block" @click="isOpenMobile = false">
            <router-view></router-view>
            <PopUpCard
                v-if="showNotification"
                link="notifications"
                :principalText="$t('alerts.globalNotifications')"
                :leftButton="{ isActive: true, text: $t('button.see') }"
                :rightButton="{ isActive: true, text: $t('button.ignore') }"
                @showOff="showOff"
            />
        </div>
        <modal-loader />
        <div id="v1-modal-bloc">
            <div id="Middle">
                <img
                    id="Middle_Img"
                    src="/Images/btn/middle_close.png"
                    onclick="Middle_It(0)"
                />
                <!-- eslint-disable-line -->
                <div id="Middle_Zone"></div>
            </div>
            <div id="Act">
                <img
                    id="Act_Img"
                    src="/Images/btn/middle_close.png"
                    onclick="Middle_It(0, 'Act')"
                /><!-- eslint-disable-line -->
                <div id="Act_Zone"></div>
            </div>
            <div id="ZCfgTel">
                <hr width="100%" />
                <table align="center" class="Transparente" style="width: 90%">
                    <tr>
                        <td colspan="4">
                            <input type="text" id="cfgjs" style="width: 100%" />
                        </td>
                    </tr>
                    <tr>
                        <td width="10%">{{ $t("bloc_erp.de") }}</td>
                        <td width="40%">
                            <input
                                type="text"
                                id="cfgdeb"
                                disabled="disabled"
                                style="width: 100%"
                            />
                        </td>
                        <td width="10%">{{ $t("bloc_erp.a") }}</td>
                        <td width="40%">
                            <input
                                type="text"
                                id="cfgfin"
                                disabled="disabled"
                                style="width: 100%"
                            />
                        </td>
                    </tr>
                </table>
                <hr width="100%" />
                <table align="center" class="Transparente" style="width: 90%">
                    <tr>
                        <td width="50%">{{ $t("bloc_erp.nom") }}</td>
                        <td width="50%">
                            <input
                                type="text"
                                id="cfgnom"
                                style="width: 100%"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t("bloc_erp.tel") }}</td>
                        <td>
                            <input
                                type="text"
                                id="cfgtel"
                                onblur="AG_SRC_TEL()"
                                style="width: 100%"
                            />
                        </td>
                    </tr>
                    <tr class="ag_video">
                        <td>{{ $t("bloc_erp.visio") }}</td>
                        <td><input type="checkbox" id="cfgvideo" /></td>
                    </tr>
                    <tr class="ag_video">
                        <td>
                            <a
                                style="font-size: 12px"
                                target="_blank"
                                href="http://cogelec.info/logicielpc"
                                >{{ $t("bloc_erp.mail") }}</a
                            >
                            <a
                                style="
                                    margin-left: 5px;
                                    font-size: 10px;
                                    color: #4f9fcf;
                                "
                                target="_blank"
                                href="http://cogelec.info/logicielpc"
                            >
                                <img
                                    alt="?"
                                    onmouseover="tooltip.init(this);"
                                    src="/Images/btn/information.png"
                                    style="
                                        width: 16px;
                                        height: 16px;
                                        margin-left: 2px;
                                    "
                                    data-tooltip="Cliquez pour découvrir, à quoi ça sert ?"
                                />
                            </a>
                        </td>
                        <td>
                            <input
                                type="text"
                                id="cfgemail"
                                style="width: 100%"
                            /><br />
                            <span
                                ><a
                                    style="
                                        font-size: 12px;
                                        font-size: 10px;
                                        color: #4f9fcf;
                                        font-style: italic;
                                    "
                                    target="_blank"
                                    href="http://cogelec.info/logicielpc"
                                    >{{ $t("bloc_erp.appPC") }}</a
                                ></span
                            >
                        </td>
                    </tr>
                    <tr class="ag_video">
                        <td colspan="2">
                            <div style="height: 6px"></div>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t("bloc_erp.telsec") }}</td>
                        <td>
                            <input
                                type="text"
                                id="cfgtels"
                                onkeyup="AG_Cfg_Tels()"
                                onchange="AG_Cfg_Tels()"
                                style="width: 100%"
                            />
                        </td>
                    </tr>
                    <tr class="ag_video ag_videos">
                        <td>{{ $t("bloc_erp.visiosec") }}</td>
                        <td><input type="checkbox" id="cfgvideos" /></td>
                    </tr>
                </table>
                <table
                    align="center"
                    class="Transparente"
                    style="width: 90%; margin-top: 10px"
                >
                    <tr>
                        <td width="40%">{{ $t("bloc_erp.color") }}</td>
                        <td width="10%">
                            <input
                                type="button"
                                value="-"
                                onclick="AG_CHG_CLR(-1)"
                                style="width: 100%"
                            />
                        </td>
                        <td width="40%">
                            <input
                                id="cfgclr"
                                disabled="disabled"
                                type="text"
                                value="Marron"
                                style="
                                    width: 100%;
                                    background-color: #944900;
                                    text-align: center;
                                "
                            />
                        </td>
                        <td width="10%">
                            <input
                                type="button"
                                value="+"
                                onclick="AG_CHG_CLR(1)"
                                style="width: 100%"
                            />
                        </td>
                    </tr>
                </table>
                <br />
                <input
                    type="button"
                    class="new_btn"
                    id="bval"
                    :value="$t('button.validate')"
                    onclick="Zones[Ag_Zone].Set(Ag_Zone_Num);"
                    style="margin-right: 5px"
                />
                <input
                    type="button"
                    class="new_btn"
                    id="bann"
                    :value="$t('button.cancel')"
                    onclick="removehideall();Mask('ZCfgTel');"
                    style="margin-left: 5px"
                />
            </div>
        </div>
    </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue"
import PopUpCard from "../components/entities/PopUpCard.vue"
import ModalLoader from "@/components/Modal/ModalLoader"

import { nextTick } from "@vue/runtime-core"
import { useToast } from "vue-toastification"
import { mapState } from "vuex"

const toast = useToast()

export default {
    name: "PrivateLayout",
    components: {
        NavigationBar,
        ModalLoader,
        PopUpCard,
    },
    data() {
        return {
            isOpenMobile: false,
            showPopUp: false,
            date: null,
            exceptions: ["print"],
        }
    },
    watch: {
        $route() {
            this.displayContent()
        },
    },
    created() {
        this.displayContent()
        window.Prog.install()
        if (!this.exceptions.includes(this.$route.params.arg1)) {
            window.Connect()
        }
    },
    computed: {
        ...mapState({
            showNotification: (state) => state.account.showNotification,
        }),
    },
    methods: {
        async displayContent() {
            await nextTick()
            eval("Middle_It(0, 'Act')")
            if (document.getElementById("Act_Zone")) {
                document.getElementById("Act_Zone").innerHTML = ""
            }
            eval("Middle_It(0, 'Middle')")
            if (document.getElementById("Middle_Zone")) {
                document.getElementById("Middle_Zone").innerHTML = ""
            }
            if (document.getElementsByClassName("middle_div").length > 0) {
                document.getElementsByClassName("middle_div")[0].remove()
            }
        },
        setOpenMobile(payload) {
            this.isOpenMobile = payload
        },
        showOff() {
            this.$store.commit("account/setShowNotification", false)
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.content-block {
    height: 90vh;
    border-radius: 50px 50px 0 0;
    background-color: white;
    overflow: hidden;

    @media all and (max-width: 1024px) {
        border-radius: 0px;
    }
}

#v1-modal-bloc :deep() {
    @import "./../assets/scss/v1-scss/style.scss";
    @import "./../assets/scss/v1-scss/datepicker.scss";
    @import "./../assets/scss/v1-scss/cle.scss";
}

@media only screen and (max-width: 768px) {
    .inbenta .inbenta-bot__launcher {
        display: none;
    }
}
</style>
