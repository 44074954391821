<template>
    <div>
        <slot name="prepend"></slot>
        <select
            class="basic-select form-select"
            :class="{ 'value-selected': modelValue > 0 }"
            :disabled="disabled"
            :name="name"
            @change="onInput"
            @input="onInput"
            @blur="handleBlur"
        >
            <option
                v-if="placeholder !== null"
                :value="value & placeholder"
                :disabled="disabled"
            >
                {{ placeholder }}
            </option>
            <option
                v-for="item in options"
                :key="item.name"
                :value="item.value"
                :selected="item.value === modelValue"
            >
                <span v-if="displayValue">{{ item.value }} - </span
                >{{ item.name }}
            </option>
        </select>
        <slot name="append"></slot>
        <span
            class="error-message"
            v-show="errorMessage || meta.valid || errorContent"
        >
            {{ errorMessage || errorContent }}
        </span>
    </div>
</template>

<script>
import { useField } from "vee-validate"
import { watch } from "vue"

export default {
    name: "BasicSelect",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        rules: {
            type: Function,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            default() {
                return []
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorContent: {
            type: String,
            default: "",
        },
        displayValue: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange(value) {
            if (value === this.placeholder) {
                value = null
            }
            this.$emit("update:modelValue", value)
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const {
            value: value,
            errorMessage,
            handleChange,
            handleBlur,
            meta,
        } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        })

        //For any change in the input, update the modelValue and emit the update:modelValue at the parent component
        const onInput = (event) => {
            handleChange(event)
            emit("update:modelValue", event.target.value)
        }

        watch(
            () => props.modelValue,
            (newVal) => {
                if (newVal !== value.value) {
                    value.value = newVal // Mise à jour de la valeur interne si modelValue change
                }
            }
        )

        return {
            onInput,
            handleChange,
            handleBlur,
            errorMessage,
            value,
            meta,
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.basic-select {
    padding: 1em 1em;
    border: none;
    background-color: $light-grey;
    border-radius: 10px;
    cursor: pointer;
    color: $blue-neutral;
    font-size: $small;
    font-family: $font-avenir-roman;
}

.value-selected {
    color: $blue-neutral;
}

.error-message {
    color: $orange;
    font-family: $font-avenir-roman;
    font-size: $small;
    width: 100%;
    margin-top: 5px;
}

.form-floating {
    position: relative;
    .form-control-plaintext ~ label,
    .form-control:focus ~ label,
    .form-control:not(:placeholder-shown) ~ label,
    .form-select ~ label {
        color: $blue-neutral;
        z-index: 10;

        &::after {
            background-color: transparent;
        }
    }
}

.form-floating {
    position: relative;
    .form-control-plaintext ~ label,
    .form-control:focus ~ label,
    .form-control:not(:placeholder-shown) ~ label,
    .form-select ~ label {
        color: $blue-neutral;
        z-index: 10;

        &::after {
            background-color: transparent;
        }
    }
}
</style>
