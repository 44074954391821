/**
 * Encode parameters into query string
 * 
 * @param {object} data Data to encode
 * 
 * @return {URLSearchParams} Data encoded
 */
export function encodeRequestParameters(data) {
    const delArray = []
    const parameters = new URLSearchParams()
    Object.keys(data).forEach(element => {
        if (element.required === false && (data[element] === undefined || data[element] === null || data[element] === '')) {
            delArray.push(element)
            delArray.forEach(element => {
                delete data[element]
            })
        } else {
            parameters.append(element, data[element])
        }
    })
    return parameters
}

/**
 * Check data format and add default values
 * 
 * @param {object} format Validation format
 * @param {object} data Data to validate
 * 
 * @return {object} New data with default values 
 * @return {boolean=false} if validation has failed 
 */
export function formatData(format, data = {}) {
    format.forEach(element => {
        if(element.required === false && data[element.name] === null){
            delete data[element.name]
        }
        if (data[element.name] === undefined) {
            // If missing parameter is required :
            // returns false
            if (element.required === true) {
                return false
            // If missing parameter has default value :
            // adding default value to data
            } else if (element.default !== undefined) {
                data[element.name] = element.default
            }
            // If parameter is not required, has no default value and no value
            // delete it from data
            else if ((element.default === undefined && (data[element.name] === null || element[element.name] === '') && element.required === false)) {
                delete data[element.name]
            }
        }
    })
    return data
}