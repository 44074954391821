export default {
  "button": {
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "resetStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf leeren (?)"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger anzeigen"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
    "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorieren"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenden"])}
  },
  "link_words": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])}
  },
  "input": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte dieses Feld ausfüllen"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Pflichfeld"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag ist ungültig"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld darf nur Zahlen enthalten"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse ist ungültig"])},
    "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Muss ", _interpolate(_named("length")), " Zeichen lang sein"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])}
  },
  "errors": {
    "oups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops !"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Fehler"])},
    "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support kontaktieren"])}
  },
  "alerts": {
    "connection": {
      "connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind eingeloggt als ", _interpolate(_named("name"))])},
      "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind ausgeloggt"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung nicht möglich"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerhafte Log-In Informationen"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Account wurde registriert"])}
    },
    "form": {
      "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Angaben sind inkorrekt oder unvollständig"])},
      "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort und die Passwortbestätigung müssen identisch sein"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen die Nutzungsbedingungen akzeptieren um einen Account erstellen zu können."])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ein Land auswählen"])},
      "industryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen eine Branche auswählen"])},
      "invalid_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene Zeit ist ungültig"])},
      "required_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen einen Tag auswählen"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ein Vigik-Bundesland auswählen"])},
      "residence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen einen Wohnanlagentyp auswählen"])}
    },
    "version": {
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Laden >hier< klicken"])},
      "new_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung der Website verfügbar"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Sie haben ", _interpolate(_named("count")), " Benachrichtigungen"]), _normalize(["Sie haben ", _interpolate(_named("count")), " Benachrichtigungen"])])},
    "globalNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benachrichtigungen"])},
    "no_mobile_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite wurde noch nicht für die Darstellung auf Mobilgeräten optimiert. Wir arbeiten derzeit daran."])},
    "access": {
      "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eröffnungsauftrag wurde erfolgreich gesendet."])}
    },
    "residence": {
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre DSGVO-Einstellungen wurden gespeichert"])}
    },
    "errors": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese ID wird bereits verwendet!"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Transponder ist bereits in diesem Treppenhaus registriert!"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Tastaturcode existiert bereits für diesen Zugang!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Telefoncode existiert bereits für diesen Zugang!"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Kombination aus Name und ID existiert bereits für diese Wohnung!"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Kurzwahlnummer wird bereits verwendet!"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Feiertag existiert bereits!"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Transponder wird bereits als Generalschlüssel verwendet!"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Kontingent (Anzahl der Karten...) Ihres GSM-Vertrags überschritten!"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese GSM-Vertragsnummer wird nicht gefunden!"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Zentraleinheit wird aus tariflichen Gründen nicht mit dieser Telefonnummer aktualisiert!"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, diesen Dienst zu ändern!"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zugang wird von einer oder mehreren Berechtigungen verwendet oder ist mit einer Zentrale verbunden, daher können Sie ihn nicht löschen!"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Berechtigung wird von einem oder mehreren Transpondern verwendet, Sie können sie nicht löschen."])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion wird momentan nur innerhalb des Microsoft Internet Explorers unterstützt."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte legen Sie Wohnungen an, um Transponder programmieren zu können"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte legen Sie die Einstellungen der Zentraleinheit vorher fest"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name für die Zentraleinheit wird bereits verwendet"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name für die Wohnung wird bereits verwendet"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name für das Treppenhaus wird bereits verwendet"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wohnungsname wird bereits für dieses Treppenhaus verwendet"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name für die Autorisierung wird bereits verwendet"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name für den Zugang wird bereits verwendet"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name für den Generalschlüssel wird bereits verwendet"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht mehr als 11 Tastaturcodes pro Tür erstellen!"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht mehr als 11 Telefoncodes pro Tür erstellen!"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht mehr als 1 Tastaturcode für diese Tür erstellen!"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht mehr als 16 R/W-Zugriffe für eine Berechtigung erstellen!"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 6 Wohnanlagen via Read/Write hinzufügen!"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 6 Treppenhäuser via Read/Write hinzufügen!"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 6 Zugänge via R/W hinzufügen!"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Zentraleinheit wird bereits verwendet"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie die Eingabe der Modul- oder Vertragsnummer."])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige GSM-Module konnten aufgrund von **GSM-Quoten** nicht aktualisiert werden!"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Zentralen sind vom Typ Read/Write und müssen in Betrieb genommen werden"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion erfordert die Installation des USB-Programmers (nur innerhalb Internet Explorer verfügbar)"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zugang wird für eine Berechtigung verwendet und kann nicht geändert werden."])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesem Zugang wurden Tastaturcodes zugewiesen, daher kann er nicht geändert werden."])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nur Scrolling Intercoms an diesen Anschluss anschließen!"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können keine Scrolling Intercoms an diesen Anschluss anschließen!"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen diesen Zugang zunächst mit einer Zentrale verbinden!"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Material ist nur in Frankreich erhältlich!"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 18 Wohnanlagen via Read/Write zuweisen!"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 18 Treppenhäuser via Read/Write zuweisen!"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 18 Zugänge via Read/Write zuweisen!"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel keine Wohnanlagen via Read/Write (HF) zuweisen!"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel keine Treppenhäuser via Read/Write (HF) zuweisen!"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel keine Zugänge via Read/Write (HF) zuweisen!"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 5 Wohnanlagen via Read/Write (HF) zuweisen!"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 5 Treppenhäuser via Read/Write (HF) zuweisen!"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diesem Generalschlüssel nicht mehr als 5 Zugängevia Read/Write (HF) zuweisen!"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können einer Berechtigung nicht mehr als 5 Zugänge via Read/Write (HF) zuweisen!"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen diese Zentraleinheit zunächst mit einem Zugang verbinden!"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seriennumer der Zentraleinheit ist ungültig!"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können einer Berechtigung nicht mehr als 10 LIGHT Zugänge zuweisen!"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Transponder oder Handsender verwenden um diese Zugänge zu öffnen!"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können einem Transponder nicht mehr als 10 LIGHT Zugänge zuweisen!"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Treppenhaus sind keine weiteren Wohnungen verfügbar"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import erfolgreich abgeschlossen"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Erweiterungskarte nicht entfernen. Es ist Hardware mit Terminal 3 oder 4 verbunden!"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr USB-Programmer unterstützt diese Funktion leider nicht"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Laden des Backups ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angesteckte Karte ist kein Backup-Speicher!"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie, ob der Backup-Speicher korrekt angesteckt wurde"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Laden des Backups ist ein Fehler aufgetreten, bitte versuchen Sie es noch einmal"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben einen Backup-Speicher an den internen Anschluss und einen am Smartcard-Leser angeschlossen..."])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Mobilschlüssel wird in diesem Treppenhaus bereits verwendet"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr USB-Programmer ist mit dieser Funktion leider nicht kompatibel"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Lesen des Speichers ist ein Fehler aufgetreten, bitte versuchen Sie es erneut"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angeschlossene Komponente ist keine Speicherkarte!"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen Sie, ob der Speicher korrekt angesteckt wurde"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Lesen des Speichers ist ein Fehler aufgetreten, bitte versuchen Sie es erneut"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die verbundene Karte ist kein Speicher für diese Zentraleinheit!"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl an Tastaturcodes für diesen Zugang erreicht!"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zugang kann mit diesem Anschluss nicht verbunden werden."])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl von SMS-Serviceversuchen erreicht"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Senden der SMS ist ein Fehler aufgetreten, bitte versuchen Sie es erneut"])},
      "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl an Tastaturcodes für diese Zentraleinheit erreicht"])},
      "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zugang kann nur an Terminal 1 angeschlossen werden."])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zugang kann nicht an diesen Typ von Zentraleinheit oder diese Anschlussnummer angeschlossen werden"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Codes sind unbekannt"])},
      "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Produktcode ist bereits aktiviert"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl an Transpondern für diese Wohnung erreicht"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl an Handsendern für diese Wohnung erreicht"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Buchstaben (a-z)(A-Z), Zahlen (0-9) und Zeichen (.,-,_) sind erlaubt, Leerzeichen sind nicht erlaubt."])},
      "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können dieser Wohnung keine weiteren Einträge hinzufügen"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bitte mindestens eine Wohnanlage aus"])},
      "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bitte mindestens ein Treppenhaus aus"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bitte mindestens eine Tür aus"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie bitte mindestens einen Zugang aus"])},
      "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie mindestens eine Agentur aus"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Transponder existiert nicht"])},
      "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl an zuweisbaren General-Transpondern erreicht"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer besitzt bereits einen Schlüssel dieses Typs"])},
      "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Eintrag wurde gelöscht"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl der erlaubten Feiertage erreicht"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zeitraum wird von anderen Zugängen verwendet und kann daher nicht gelöscht werden"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Woche wird in einem Zeitraum verwendet"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzer existiert nicht"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht zugänglich!"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzername wird bereits verwendet"])},
      "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Gebäudename wird bereits verwendet"])},
      "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten zu verarbeiten"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Transponder ist nicht vorhanden"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Starten des Updates, bitte versuchen Sie es später noch einmal"])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Transponder ist ungültig oder wird bereits verwendet"])},
      "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Barcode existiert nicht"])},
      "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Barcode wurde bereits verwendet"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dienst zur Erstellung eines Kontos ist nicht verfügbar. Bitte versuchen Sie es später noch einmal."])},
      "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Account kann nicht verschoben werden"])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer besitzt bereits einen Schlüssel dieses Typs"])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keine Unterkonten"])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben einen Backup-Speicher an den internen Anschluss und einen am Smartcard-Leser angeschlossen..."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name wird bereits für einen anderen Zeitraum verwendet"])},
      "201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zeitraum wird verwendet und kann daher nicht gelöscht werden"])},
      "202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten entsprechen Ihrer Suche"])},
      "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das eingegebene Passwort ist falsch"])},
      "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie mindestens einen Empfänger aus"])},
      "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Sie verwalten mehr Wohnungen als vertraglich festgelegt. Sie können Ihre Zentraleinheit daher nicht aktualisieren..."])},
      "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Die GSM-Telefonnummer ist leer, Sie können Ihre Zentrale nicht aktualisieren"])},
      "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Sie haben die maximal zulässige Anzahl an internationalen Telefonnummern überschritten"])},
      "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Ihr Vertrag/Abonnement ist mit dieser Hardwarekonfiguration nicht kompatibel"])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie, dass Ihr Paket nicht für Videoanrufe zugelassen ist. Bitte abonnieren Sie die Option \"Video\" oder deaktivieren Sie die Option \"Videoanrufe\" in den entsprechenden Formularen."])},
      "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diese Art von IntraBox Eco Kit in einer \"einfachen Wohnanlage\" nicht anmelden"])},
      "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht mehr als zehn Transponder dieses Typs programmieren."])},
      "800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser GSM-Vertrag/diese Vertragsnummer wird bereits genutzt"])},
      "801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support."])},
      "802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Seriennummer des GSM-Moduls oder die Telefonnummer an."])},
      "803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesem Vertrag wurde keine Hardware zugewiesen"])},
      "804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Art des Vertrages ist nicht mit der Art der erstellten Wohnanlage kompatibel"])},
      "805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um dieses Modul zu registrieren, wählen Sie zunächst Ihre Hardware (Gegensprechanlage, Lesegerät...) und dann \"Zentrale konfigurieren\""])},
      "806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung Ihr Abonnement ist ausgesetzt, bitte wenden Sie sich an Ihren persönlichen Ansprechpartner bei INTRATONE oder an unsere allgemeine Rufnummer (0211 601770)."])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Aktualisierung des DisplayBoards läuft. Bitte warten..."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Text ist zu lang, bitte kürzen Sie ihn"])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen mindestens die Spalte \"Wohnungsname\" auswählen"])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Spalte gibt den Namen des Datensatzes an, aber die primäre Telefonnummer fehlt"])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler hat den Import abgebrochen"])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine sekundäre Telefonnummer ist ungültig"])},
      "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine primäre Telefonnummer ist ungültig"])},
      "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzername ist ungültig"])},
      "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Name ist ungültig"])},
      "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Wohnungsname ist ungültig"])},
      "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Seriennummer ist ungültig"])},
      "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es fehlt eine primäre Telefonnummer"])},
      "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzername wurde wurde ohne den Namen der Bewohnerdatei eingetragen."])},
      "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine sekundäre Telefonnummer wurde ohne den Namen der Bewohnerdatei eingetragen."])},
      "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Kurzwahlnummer wurde ohne den Namen der Bewohnerdatei eingetragen."])},
      "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Anzeigegruppe wurde ohne den Namen der Bewohnerdatei eingetragen."])},
      "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine primäre Telefonnummer wurde ohne den Namen der Bewohnerdatei eingetragen."])},
      "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bildformat wird nicht unterstützt"])},
      "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine erweiterte Verwaltung ist nicht möglich, der Nutzer besitzt einen globalen Zugriff auf die Wohnanlagen"])},
      "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kurzwahlnummer wird bereits verwendet"])},
      "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bild ist zu groß"])},
      "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen eine Spalte für die Treppenhäuser und eine Spalte für die Wohnungsnamen auswählen"])},
      "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bild wird verwendet, Löschen nicht möglich."])},
      "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Dateityp ist nicht kompatibel"])},
      "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Senden der Daten"])},
      "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzeigedauer muss einen positiven Wert haben"])},
      "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Startdatum ist ungültig"])},
      "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Enddatum ist ungültig"])},
      "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Startdatum muss vor dem Enddatum sein"])},
      "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Speichern"])},
      "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Datei"])},
      "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei konnte nicht importiert werden"])},
      "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte definieren Sie den betreffenden Zugang"])},
      "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschung von Bewohnern abgeschlossen..."])},
      "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschung von Transpondern und Handsendern abgeschlossen..."])},
      "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Daten im Formular sind ungültig"])},
      "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen über diese Wohnung konnten nicht gefunden werden"])},
      "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer im falschen Format"])},
      "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abrufen der Telefonnummer"])},
      "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Transponder kann nicht hinzugefügt werden"])},
      "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abrufen der Informationen des Transponders"])},
      "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Transponder eines bestimmten Typs"])},
      "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu viele Transponder eines bestimmten Typs"])},
      "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programminfo kann nicht abgerufen werden"])},
      "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen der Datei"])},
      "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren der Datei"])},
      "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Datei ist bereits mit einer Telefonnummer verknüpft"])},
      "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Mehrere Datensätze in dieser Wohnung"])},
      "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abrufen der Datensatzinformationen"])},
      "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen des Transponders"])},
      "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Diese Aktion erfordert, dass Sie die mit Ihrem Modul verbundene Connect.iT-Antenne entfernen."])},
      "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Ihr Account ist bereits ein Admin-Account"])},
      "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht zu einem Admin-Account geändert werden"])},
      "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht zu einem Admin-Account geändert werden. Ihre E-Mailadresse ist nicht eingetragen"])},
      "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Der Name der Zentraleinheit muss eingegeben werden"])},
      "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Konfigurationsproblem"])},
      "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Der Name des Zugangs muss eingegeben werden"])},
      "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Ihr Vertrag erlaubt das Hinzufügen dieser Hardware nicht"])},
      "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Das Material wurde bereits registriert"])},
      "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Der Vertrag konnte nicht identifiziert werden, bitte vervollständigen Sie die Informationen"])},
      "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Erstellung eines Kontos ist ein Fehler aufgetreten."])},
      "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."])},
      "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion von Ihrem Administrator gesperrt."])},
      "API_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über die erforderlichen Berechtigungen für diese Aktion."])},
      "PARAMS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "PARAMS_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "ACCES_TYPEEXIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Die Zugriffsart wurde bereits deklariert."])},
      "ACCES_TYPEFAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Aufforderung, einen Zugriffstyp zu erstellen, ist ein Fehler aufgetreten."])},
      "POLICY_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie verfügen nicht über die erforderlichen Berechtigungen für diese Aktion."])},
      "WD_0_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Montag mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_1_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Dienstag mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_2_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Mittwoch mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_3_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Donnerstag mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_4_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Freitag mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_5_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Samstag mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_6_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Zeitfenster am Sonntag mit einer höheren Endzeit als der Anfangszeit"])},
      "WD_0_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Montag festgestellt"])},
      "WD_1_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Dienstag festgestellt"])},
      "WD_2_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Mittwoch festgestellt"])},
      "WD_3_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Donnerstag festgestellt"])},
      "WD_4_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Fraeitag festgestellt"])},
      "WD_5_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Samstag festgestellt"])},
      "WD_6_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Überschneidung von 2 Zeiten wurde für Sonntag festgestellt"])},
      "WD_0_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "WD_1_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "WD_2_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "WD_3_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "WD_4_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "WD_5_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "WD_6_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal."])},
      "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eines der ausgefüllten Felder ist nicht korrekt. Bitte überprüfen Sie es."])}
    }
  },
  "language": {
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Sprache"])}
  },
  "global": {
    "entities": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnanlage"])},
      "residenceType": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfache Wohnanlage"])},
        "simplified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinfachte Wohnanlage"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexe Wohnanlage"])}
      },
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
      "stairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treppenhaus"])},
      "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewohnerdatei"])}
    },
    "status": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
      "updateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle aktualisieren"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird aktualisiert..."])},
      "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss aktualisiert werden"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung fehlgeschlagen"])},
      "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbetriebnahme"])},
      "optionPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass-Option"])},
      "updateResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen an Ihrer Wohnanlage angewandt"])},
      "inService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Betrieb"])},
      "notInService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außer Betrieb"])},
      "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
    },
    "modal": {
      "edit-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitspannen ändern"])},
      "create-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster erstellen"])},
      "copie-day-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren von Zeitplänen"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung !"])},
      "rgpd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["DSGVO für das Gebäude Zutrittsmanagement : ", _interpolate(_named("name"))])},
      "deviceNotCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Gerät, das Sie zum Programmieren Ihres Zubehörs verwenden, ist nicht mit USB-Programmiergeräten kompatibel. Bitte verwenden Sie einen Computer."])},
      "delete_cylinder?_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Zylinder entfernen wollen? Dieser Vorgang ist nicht umkehrbar."])},
      "window_open_site_in_host_please_close_it": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben bereits ein Fenster mit der Verwendung eines Terminplaners auf der Seite ", _interpolate(_named("name")), " geöffnet. Bitte schließen Sie es, damit Sie Ihren Programmierer auf dieser Seite verwenden können."])},
      "device_using_program_accessories_not_compatible_USB_programmers_use_computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Gerät, das Sie zur Programmierung Ihres Zubehörs verwenden, ist nicht mit USB-Controllern kompatibel. Bitte verwenden Sie einen PC"])},
      "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihre Programmierer-Version aktualisieren, um Zubehör hinzufügen oder aktualisieren zu können."])},
      "valid": {
        "week-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie die neuen Zeiträume"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Änderungen rückgängig machen wollen?"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen die Änderungen validieren"])},
        "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
        "access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie den neuen Zugangstyp"])},
        "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to remove this type of access ?"])},
        "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wohnanlage löschen"])},
        "politicsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik Änderung ausstehend"])},
        "politicsUpdateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that any customizations made previously will be removed if you validate this update. Would you like to use it ?"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Werkseinstellung zurücksetzen"])}
      },
      "vigik": {
        "not-enough-time-slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl von Zeitfenstern pro Tag erreicht. Bitte löschen Sie einige, um neue zu erstellen."])}
      },
      "providersStatus": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zusammenfassung der Änderungen (", _interpolate(_named("count")), ")"])},
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisiert"])},
        "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert"])}
      },
      "doubleAuth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA (Zwei Faktor Authentifizierung)"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An diese E-Mail-Adresse wurde ein einmaliger Bestätigungscode gesendet:"])},
        "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigungscode"])},
        "error": {
          "attempt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Der Bestätigungscode ist falsch. Sie haben noch ", _interpolate(_named("count")), " Versuche"]), _normalize(["Der Bestätigungscode ist falsch. Sie haben noch ", _interpolate(_named("count")), " Versuche"])])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte starten Sie den Vorgang erneut. Sie werden in 10 Sekunden zur Anmeldeseite weitergeleitet."])},
          "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code ist nicht mehr gültig."])}
        }
      }
    },
    "array": {
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste leeren"])},
      "noSearchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Daten zu Ihrer Suche"])}
    }
  },
  "weekType": {
    "weekday": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["donnerstag"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])}
    },
    "copie-choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An welchen Tagen möchten Sie den Stundenplan am  ", _interpolate(_named("day")), " kopieren ?"])},
    "max-different-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die maximale Anzahl unterschiedlicher Tage im Kalender erreicht. Möchten Sie den Stundenplan eines anderen Tages wiederverwenden?"])}
  },
  "vigik": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik+ Konfiguration"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Zugriffstyps"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionsweise kopieren"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Erstellung eines neuen Zugangstyps rückgängig machen?"])},
      "create-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen neuen Zugangstyp einrichten?"])},
      "openingPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorität der Öffnung"])},
      "obligatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
      "minimalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zeitfenster muss länger als 15 Minuten sein"])},
      "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
    },
    "modale": {
      "copySchedule": {
        "copyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren Sie"])},
        "onNextDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An den folgenden Tagen"])}
      },
      "createSchedule": {
        "limitAtFiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nicht mehr als fünf Zeitfenster pro Tag erstellen."])},
        "existingSchedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt bereits einen Slot zu diesen Zeiten"])}
      }
    },
    "global": {
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierte Anbieter"])},
      "bannedProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbotene Anbieter"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "whitelisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Benutzerdefiniert"]), _normalize([_interpolate(_named("count")), " benutzerdefiniert"]), _normalize([_interpolate(_named("count")), " benutzerdefiniert"])])},
      "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Verboten"]), _normalize([_interpolate(_named("count")), " verboten"]), _normalize([_interpolate(_named("count")), " verboten"])])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardvorgabe"])},
      "activitiesAllows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Aktivität"])},
      "activitiesBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unerlaubte Tätigkeit"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
      "allAutorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle erlaubt"])}
    },
    "navigation": {
      "listAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste der Zugriffsarten"])},
      "listPersonalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste der Personalisierungen"])},
      "list-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste der Anbieter"])},
      "list-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste der Aktivitäten"])},
      "searchCharacterLimiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie mindestens 3 Zeichen aus"])}
    },
    "config": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Erstellung eines Vigik + kompatiblen Lesers müssen Sie den Typ des Lesers auswählen"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit wird festgelegt, welches Vigik-Profil verwendet werden soll"])},
      "accessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des Zugangs"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "accessNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Zugriffe"])},
      "createAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Zugangstyp erstellen"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "confirmPoliticsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Vigik-Richtlinie ist verfügbar. Möchten Sie sie nutzen?"])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anpassen der Zugriffsart ", _interpolate(_named("name"))])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie auf dieser Seite die Aktivitäten der Anbieter"])},
      "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Aktivität"])},
      "providerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Anbieters"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbotene Anbieter"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt"])},
      "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
      "reset-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitpläne auf Standard zurücksetzen"])},
      "reset-to-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitpläne auf Standard zurücksetzen"])},
      "reset-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriffsarten auf Standard zurücksetzen"])},
      "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie mit den Standardeinstellungen zurücksetzen ?"])},
      "activityStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Aktivität"])},
      "activityTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten der Aktivität"])},
      "providerTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten des Anbieters"])},
      "timetablesCustomised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierte Zeitpläne"])},
      "timetablesDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Zeitpläne"])}
    },
    "schedule": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gewünschte Zeiten für ", _interpolate(_named("name")), " auswählen"])},
      "edit-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie Ihre Änderungen speichern?"])},
      "edit-schedule-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie Ihre Änderungen rückgängig machen?"])},
      "update-schedule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Zeitplan für ", _interpolate(_named("name")), " wurde geändert."])},
      "reset-schedule-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte ändern Sie die Zeitpläne, damit Sie sie zurücksetzen können."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])},
      "selectAllDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den ganzen Tag auswählen"])}
    },
    "activity": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach einer Aktivität oder einem Dienstleistungsanbieter"])},
      "modification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " änderung"]), _normalize([_interpolate(_named("count")), " änderungen"])])}
    },
    "provider": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Anbieter autorisiert für ", _interpolate(_named("name"))])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Anbieters"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach einem Anbieter zum Anpassen"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter bearbeiten"])},
      "searchCustomProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach einem Anbieter zum Anpassen"])},
      "forbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Anbieter sperren"])},
      "searchForbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach einem zu sperrenden Anbieter"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liste der Zugriffe des Typs ", _interpolate(_named("name"))])},
      "alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben ", _interpolate(_named("count")), " Zugriffe außerhalb Ihrer Verwaltung"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Wohnheims"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
      "cage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käfig"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang"])},
      "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Art des Zugriffs abschaffen ?"])},
      "update-access-check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Zugriffe von ", _interpolate(_named("name")), " wurden geändert."])},
      "create-access-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Zugriffstyp ", _interpolate(_named("name")), " wurde erfolgreich erstellt."])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachten Sie, dass alle zuvor vorgenommenen Anpassungen gelöscht werden, wenn Sie diese Wiederherstellung bestätigen. Möchten Sie die Standardeinstellungen wiederherstellen?"])}
    }
  },
  "exportFobs": {
    "fobsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Transponder"])},
    "fobsNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 Transponder"]), _normalize(["1 Transponder"]), _normalize([_interpolate(_named("count")), " Transponder"])])},
    "fobsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minCount")), " von ", _interpolate(_named("maxCount"))])},
    "fobsSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transponder-Nr."])},
    "fobsLastUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt verwendet"])},
    "fobsFilterDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Wahl eines Zeitraums -"])},
    "fobsFilterOneWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["älter als 1 Woche"])},
    "fobsFilterTwoWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["älter als 2 Wochen"])},
    "fobsFilterOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["älter als 1 Monat(e)"])},
    "fobsFilterThreeMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["älter als 3 Monate"])},
    "fobsFilterSixMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["älter als 6 Monate"])},
    "fobsFilterOneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["älter als 1 Jahr"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach einem Transponder oder einer Wohnung suchen"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Transponder vorhanden"])},
    "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Daten gefunden, die Ihrer Suche entsprechen"])},
    "fobsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Transponder löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."])}
  },
  "publicHome": {
    "residenceTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Wohnanlage übertragen"])},
    "manuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktinfos und weitere Dokumente"])},
    "returnCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Retoureschein erstellen"])},
    "2gInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info réseau 2G"])},
    "programmerSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation USB Transponder-Programmierer"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Support"])}
  },
  "navigation": {
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Wohnanlagen"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generalschlüssel"])},
    "bulletinBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info-Display"])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeiträume"])},
    "manageSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten / Suchen"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere anderen Tools"])},
    "texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS senden"])},
    "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik-Dienste (in Deutschland nicht verfügbar)"])},
    "propass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProPass (in Deutschland nicht verfügbar)"])},
    "programmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmierung"])},
    "programmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmierung 2"])},
    "keySafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsseltresor"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Provider"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mülleimer"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "listBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zu den Wohnanlagen"])},
    "residenceBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Wohnanlage"])},
    "buildingBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Gebäude"])},
    "stairwellBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Treppenhaus"])},
    "old_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühere version"])},
    "vigik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik+ Dienstleistungen"])}
  },
  "login": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzername"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort"])},
    "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe mein Passwort vergessen"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe noch keinen Account"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich Willkommen auf der Verwaltungs-Website von Intratone"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier verwalten Sie alle Daten, Zugänge und Inhalte von Intratone Gegensprechanlagen, Zutrittskontrollsystemen und Info-Displays auf nur einer Online-Plattform."])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Account"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Benutzername"])},
    "idSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr vollständiger Benutzername lautet: ", _interpolate(_named("username"))])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie Ihr Passwort"])},
    "anssiPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort muss mindestens beinhalten: "])},
    "passwordRule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Zeichen,"])},
    "passwordRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Großbuchstabe"])},
    "passwordRule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Kleinbuchstabe"])},
    "passwordRule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Zahl"])},
    "passwordRule5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sonderzeichen"])},
    "passwordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort muss: "])},
    "passwordRules1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aus mindestens 8 Zeichen bestehen"])},
    "passwordRules2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diese 3 Kriterien erfüllen:"])},
    "passwordRules3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es muss mindestens ein Großbuchstabe enthalten sein"])},
    "passwordRules4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es muss mindestens ein Kleinbuchstabe enthalten sein"])},
    "passwordRules5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es muss eine Ziffer (0-9) enthalten sein"])},
    "passwordRules6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es muss ein Sonderzeichen (,.-!?#+ etc.) enthalten sein"])},
    "doubleAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie die 2FA (2 Faktor Authentifizierung). Bei jeder Anmeldung wird ein einmaliger Code an die angegebene E-Mail-Adresse gesendet. Diese Option kann jederzeit deaktiviert werden."])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Nachname"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Vorname"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Straße"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Postleitzahl"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Stadt"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Land"])},
    "chooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihr Land"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Firma"])},
    "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Tätigkeitsbereich"])},
    "chooseIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihr Tätigkeitsbereich"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Telefonnummer"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mailadresse"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren Sie die"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "alertSpecialCharacter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erlaubt sind Buchstaben (a-z), Zahlen (0-9) und Zeichen (", _interpolate(_named("specialCharacter")), "), aber keine Leerzeichen."])}
  },
  "forgottenPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Ihr Kennwort vergessen?"])},
    "giveMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die E-Mail-Adresse Ihres Kontos ein, und wir senden Ihnen eine Anleitung zur Erneuerung Ihres Passworts zu."])},
    "mailOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail oder Benutzername"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihr neues Passwort"])},
    "error": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die beiden Passwörter sind unterschiedlich."])},
      "codeProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie den Code"])},
      "notRespect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort entspricht nicht den Empfehlungen."])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde geändert. Sie werden in 10 Sekunden zur Anmeldeseite weitergeleitet."])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfssoftware mit technischem Support"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie diese Links, um den Fernzufriff durch einen Supportmitarbeiter während eines Telefonanrufs zu ermöglichen. (Beachten Sie hierzu Ihre internen Sicherheitsrichtlinien)"])}
  },
  "residencesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Wohnanlagen"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Wohnanlage suchen"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Wohnanlage"]), _normalize([_interpolate(_named("count")), " Wohnanlage"]), _normalize([_interpolate(_named("count")), " Wohnanlage (", _interpolate(_named("first")), " bis ", _interpolate(_named("last")), ")"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Wohnanlage erstellen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Wohnanlage ändern"])},
    "caption": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardwert"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierter Wert"])}
    },
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Aktualisierung"])}
    },
    "form": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Liste der Wohnanlagen"])},
      "types": {
        "title-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig"])},
        "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinfacht"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis-"])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Wohnanlage umfasst mehrere Gebäude oder mehrere Treppenhäuser."])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Wohnanlage umfasst nur ein Treppenhaus und mehrere Wohnungen."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie möchten den Parkplatz mit einem einzigen HF-Empfänger verwalten."])}
      },
      "residence-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Wohnanlage"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik+ Landkrkeis"])},
      "vigik-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie das eingegebene Vigik+ Bundesland"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "agence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentur"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie Ihre Wohnanlage löschen ?"])},
      "locative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietverwaltung aktivieren"])},
      "liberal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung berufliche Tätigkeit"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSGVO-Ereignisse"])},
      "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wohnanlage löschen"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
      "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "advancedOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Optionen"])},
      "residenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ der Wohnanlage"])},
      "autorisation": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Berechtigung"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Berechtigung (Fernbedienung)"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Berechtigung (Mobile key)"])},
        "ipass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnanlage wird von iPass’ verwaltet"])}
      }
    },
    "modal": {
      "rgpd": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewohner"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrie"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diensleister"])},
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniert"])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind eine Hausverwaltung oder eine Wohnungsgesellschaft. Der Zutrittskontroll ist von Ihren Mietern oder Eigentümer benutzt. Die gespeicherten Informationen bleiben anonym."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind ein privates oder öffentliches Unternehmen: Die Zugangskontrolle wird von den Angestellten meines Unternehmens genutzt. Die Ereignisse sind 3 Monate lang sichtbar. Diesen Status für Wohnanlagen zu verwenden, ist gesetzlich verboten."])},
        "detail-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zugangskontrolle wird von Dienstleistern genutzt (Reinigungsbetrieben, Gärtnereien etc.), die nicht bei meinem Unternehmen angestellt sind. Diese Ereignisse sind 3 Tage lang sichtbar. Diesen Status für Wohnanlagen zu verwenden, ist gesetzlich verboten."])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitsstufe"])},
        "cage-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Treppenhauses"])}
      }
    }
  },
  "entityDetails": {
    "tabs": {
      "residenceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Wohnanlage"])},
      "buildingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zum Gebäude"])},
      "stairwellDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zum Treppenhaus"])},
      "apartmentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Wohnung"])},
      "authorisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
      "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
      "centralUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentraleinheiten"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Optionen"])},
      "liberale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufliche Tätigkeit"])},
      "subaccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkonten"])},
      "intercoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf den Intercoms angezeigt"])},
      "connectit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect.it-Sortiment"])}
    },
    "actions": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
      "assistedImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützter Import"])},
      "manualImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manueller Import"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support kontaktieren"])},
      "stairwellExportA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung - fob(s) - Nb fob(s) - Name(s) - Benutzername - Name - Signature"])},
      "stairwellExportB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung - fob(s) - Nb fob(s) - Name(s) - Kennung - P Tel - S Tel - Anruf - Gruppe"])},
      "stairwellExportC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldeliste"])}
    },
    "externalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang außerhalb des Gebäudes"])},
    "internalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang und Hardware"])},
    "accessories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Kein Zubehör"]), _normalize([_interpolate(_named("count")), " Zubehör"]), _normalize([_interpolate(_named("count")), " Zubehör"])])},
    "resident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Keine Bewohner"]), _normalize([_interpolate(_named("count")), " Bewohner"]), _normalize([_interpolate(_named("count")), " Bewohner"])])},
    "floor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Erdgeschoss"]), _normalize([_interpolate(_named("count")), ". OG"]), _normalize([_interpolate(_named("count")), ". OG"])])},
    "basement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Erdgeschoss"]), _normalize([_interpolate(_named("count")), " erstes OG"]), _normalize([_interpolate(_named("count")), " drittes OG"])])}
  },
  "accessCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Zugang erstellen"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ereignisse"])},
    "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
    "badges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Transponder / Handsender (", _interpolate(_named("count")), ")"])},
    "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen des Zugangs"])}
  },
  "apartmentsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wohnungen (", _interpolate(_named("count")), ")"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Wohnung erstellen"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "firstResident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Eintrag"])},
      "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewohner"])},
      "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transponder"])}
    },
    "noAuthorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nicht das Recht, die Wohnungen zu konsultieren."])}
  },
  "intercomsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf den Intercoms angezeigt"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    }
  },
  "codesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes nach Zugang"])},
    "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Zugang"]), _normalize([_interpolate(_named("count")), " Zugang"]), _normalize([_interpolate(_named("count")), " Zugänge"])])},
    "keypadsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastatur-Codes"])},
    "phoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon-Codes"])}
  },
  "centralUnitsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentraleinheiten"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Zentraleinheit"]), _normalize([_interpolate(_named("count")), " Zentraleinheit"]), _normalize([_interpolate(_named("count")), " Zentraleinheiten (", _interpolate(_named("first")), " bis ", _interpolate(_named("last")), ")"])])},
    "realTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echtzeit"])},
    "readWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read/Write"])},
    "lora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect-It-kompatibel"])},
    "searchCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche eine Zentraleinheit"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status der Aktualisierung"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])}
    }
  },
  "keyCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transponder erstelllen"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transponder"])},
    "teleco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fernbedienung"])},
    "serial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schlüsselnummer ", _interpolate(_named("serial"))])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie diesen Transponder wirklich löschen ", _interpolate(_named("serial")), "?"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil-Schlüssel "])},
    "kibolt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schlüssel muss geladen werden"])},
      "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schlüssel wird als verloren gemeldet"])}
    },
    "copie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiertes Namensschild"])},
    "colors": {
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwarz"])},
      "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
      "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grün"])},
      "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blau"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rot"])},
      "brown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Braun"])},
      "grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grau"])},
      "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelb"])},
      "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])},
      "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lila"])},
      "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiß"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
    }
  },
  "residenceCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnanlage erstellen"])}
  },
  "residentCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewohnerdatei erstellen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "mainNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primäre Telefonnummer"])},
    "sideNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundäre Telefonnummer"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Bewohner ", _interpolate(_named("Name")), " wirklich löschen?"])}
  },
  "stairwellCard": {
    "createStairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treppenhaus erstellen"])},
    "createBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude erstellen"])},
    "accesses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zugang (", _interpolate(_named("count")), ")"])},
    "stairwells": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Treppenhaus"]), _normalize([_interpolate(_named("count")), " Treppenhäuser"])])},
    "apartments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Wohnung"]), _normalize([_interpolate(_named("count")), " Wohnungen"])])}
  },
  "subaccountsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unterkonten (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "fisrtName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mailadresse"])}
    }
  },
  "agencesList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agenturen (", _interpolate(_named("Zahl")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])}
    }
  },
  "text": {
    "userDataLaws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben das Recht, auf die Sie betreffenden Daten zuzugreifen, sie zu ändern, zu berichtigen und zu löschen (Art. 34 zum Gesetz “Informatik und Freiheit“). Um dieses Recht zu beanspruchen, wenden Sie sich bitte an"])},
    "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail : "])},
    "emailIntratoneSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.de"])},
    "linkEmailIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
    "linkEmailDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.de"])},
    "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederkasseler Lohweg 191"])},
    "contactCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40547 Düsseldorf"])},
    "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer : +49 (0)211 601 7700"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
  },
  "brands": {
    "windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows"])},
    "apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple"])}
  },
  "bloc_erp": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bei"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "visio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videoanruf"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mailadresse"])},
    "appPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für Videoanrufe auf dem PC erforderlich"])},
    "telsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Telefon"])},
    "visiosec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videoanruf auf 2. Telefon"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])}
  },
  "redirect": {
    "https": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitung auf die Website in HTTPS"])},
    "http": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterleitung auf die Website in HTTP"])}
  },
  "MCI": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Compte Intratone"])},
    "select": {
      "gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis gestionnaire"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis résident"])}
    },
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder à votre compte résident, cliquez sur le bouton ci-dessous."])}
  },
  "module": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu ersetzende Module"])},
    "excelExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Excel exportieren"])},
    "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["austauschen"])},
    "table": {
      "centraleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrale"])},
      "serialModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer des Moduls"])},
      "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz für Ersatz"])},
      "portaconnectCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaconnect-kompatible Referenz"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen Sie das Modul"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach einem Wohnort oder einer Modulseriennummer"])},
      "portaconnectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn es sich um ein Portal handelt, können Sie das Portaconnect-Angebot nutzen. Erfahren Sie mehr darüber, indem Sie <a href=\"https://portaconnect.intratone.fr/\">hier</a> klicken."])},
      "finishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Abschaltung von 2G"])}
    },
    "toReplace": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Modul"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu ersetzendes Modul"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Telefonnummer"])},
      "centrale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Zentrale"])}
    },
    "replacement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul ersetzen"])},
      "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Telefonnummer"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilschlüssel herunterladen"])}
    },
    "error": {
      "notCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Modul ist nicht kompatibel, um die vorhandene Hardware zu ersetzen"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung !"])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Austausch zu bestätigen, laden Sie bitte die Liste der von der Änderung der Telefonnummer betroffenen Mobilschlüssel herunter, damit Sie die betroffenen Bewohner benachrichtigen können."])},
      "isNotFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die Seite verlassen, ohne die Ersetzung abzuschließen, wird diese nicht berücksichtigt. Möchten Sie die Seite verlassen?"])},
      "noDataAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der zu ersetzenden Module leer"])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Daten gefunden, die Ihrer Suche entsprechen."])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Ersatz von Modul ", _interpolate(_named("oldName")), " durch Modul ", _interpolate(_named("newName")), " wird berücksichtigt."])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Modul wird aktualisiert, sobald es eingeschaltet ist. Der Austausch in der Verwaltungsseite wird zu diesem Zeitpunkt vorgenommen."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch abschließen"])}
    }
  },
  "date": {
    "january": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " januar ", _interpolate(_named("year"))])},
    "february": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " februar ", _interpolate(_named("year"))])},
    "march": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " märz ", _interpolate(_named("year"))])},
    "april": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " april ", _interpolate(_named("year"))])},
    "may": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " mai ", _interpolate(_named("year"))])},
    "june": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " juni ", _interpolate(_named("year"))])},
    "july": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " juli ", _interpolate(_named("year"))])},
    "august": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " august ", _interpolate(_named("year"))])},
    "september": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " september ", _interpolate(_named("year"))])},
    "october": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " oktober ", _interpolate(_named("year"))])},
    "november": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " november ", _interpolate(_named("year"))])},
    "december": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " dezember ", _interpolate(_named("year"))])}
  },
  "kibolt": {
    "connected_key_programmer_but_nothing_happens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihren Schlüssel in das Programmiergerät gesteckt, aber es passiert nichts?"])},
    "connected_cylinder_programmer_but_nothing_happen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Ihren Zylinder an das Programmiergerät angeschlossen, aber es passiert nichts?"])},
    "plug_in_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie Ihren Controller an"])},
    "key_is_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schlüssel wird gelöscht"])},
    "key_is_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schlüssel ist auf dem neuesten Stand"])},
    "delete_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen fehlgeschlagen. Erneut versuchen."])},
    "update_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung fehlgeschlagen. Erneut versuchen."])},
    "currently_being_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird gelöscht"])},
    "currently_updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird aktualisiert"])},
    "then_wait_few_seconds_be_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...warten Sie ein paar Sekunden, bis es von der App erkannt wird."])},
    "plug_then_wait_seconds_to_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen Sie das Gerät an und warten Sie ein paar Sekunden, bis es von der App erkannt wird."])},
    "your_programmer_not_responding_even_though_it_is_plugged_in?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reagiert Ihre Programmierer nicht, obwohl er eingesteckt ist?"])},
    "replace_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen Sie den Zylinder"])},
    "move_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen Sie den Zylinder"])},
    "remove_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zylinder löschen"])},
    "size_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe des Zylinders"])},
    "cylinder_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zylinder erkannt"])},
    "insert_replacement_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einsetzen des Ersatzzylinders"])},
    "insert_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einsetzen des Zylinders"])},
    "insert_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Schlüssel einführen"])},
    "wait_seconds_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... Warten Sie dann einige Sekunden, bis es von der App erkannt wird."])},
    "keys_still_open_until_updated_or_barrel_is_re_declared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel können noch öffnen, bis sie aktualisiert werden"])},
    "unplug_kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennen Sie das Kiprog für die Dauer der Aktualisierung nicht vom Stromnetz."])},
    "replacement_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch in Arbeit."])},
    "can_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können den Kiprog aus dem Zylinder entfernen."])},
    "replacement_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austausch abgeschlossen"])},
    "be_careful_cylinder_not_functional_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie, dass der Zylinder nicht funktionsfähig ist. Bitte versuchen Sie es erneut."])},
    "updates_completed_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung abgeschlossen. Sie können den Kiprog aus dem Zylinder entfernen."])},
    "update_progress_not_unplug_Kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung läuft. Trennen Sie den Kiprog für die Dauer der Aktualisierung nicht."])},
    "requires_administrator_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfordert Administratorrechte"])},
    "remote_control_and_badge_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmiergerät für Fernbedienungen und Transponder"])},
    "kibolt_key_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmiergerät für Kibolt"])},
    "unknown_error_contact_customer_or_try_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Kundendienst oder kontaktieren Sie uns am Ende des Tages."])},
    "error_occurred_during_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während der Aktualisierung ist ein Fehler aufgetreten."])},
    "cylinder_already_exists_insert_another_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zylinder im Programmiergerät existiert nicht oder ist bereits in Gebrauch. Bitte setzen Sie einen anderen Zylinder ein."])},
    "cylinder_not_correspond_cylinder_updated_insert_correct_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zylinder im Programmiergerät stimmt nicht mit dem zu aktualisierenden Zylinder überein. Bitte setzen Sie den richtigen Zylinder ein."])},
    "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihre Programmierer-Version aktualisieren, um Zubehör hinzufügen oder aktualisieren zu können."])},
    "name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Tür"])},
    "type_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ des Zylinders"])},
    "version_soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft-Version"])},
    "version_boot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boot-Version"])},
    "this_is_name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist der Name der Tür"])},
    "kibolt_cylinder_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt-Zylinder-Aktualisierungen"])},
    "kibolt_cylinder_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt-Zylinder-Ersatz"])},
    "simple_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zylinder für Einzeltür"])},
    "double_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zylinder für Doppeltür"])},
    "button_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zylinder für Tür mit Knauf"])}
  },
  "TO_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung"])},
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "TECHNICAL_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische Details"])},
  "TRY_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholung"])},
  "CLICK_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier klicken"])},
  "REPLACEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersatz"])},
  "UPDATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
  "CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbunden"])},
  "NOT_CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht Verbunden"])},
  "INSTALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installieren"])},
  "SERIAL_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer"])},
  "VERSION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versionsnummer"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "SHOW_MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
  "SERIAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummer"])},
  "ADVANCED_OPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Informationen"])},
  "MOVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen nach"])},
  "KIBOLT_ACCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt Zugang"])}
}