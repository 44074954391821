const state = () => ({
    vigikBreadCrumb: [],
    classicBreadCrumb: [],
})

const getters = {
    getVigikBreadCrumb: (state) => {
        return state.vigikBreadCrumb
    },

    getClassicBreadCrumb: (state) => {
        return state.classicBreadCrumb
    },
}

const actions = {}

const mutations = {
    setVigikBreadCrumb(state, data) {
        state.vigikBreadCrumb = data
    },

    pushVigikBreadCrumb(state, data) {
        state.vigikBreadCrumb.push(...data)
    },

    setClassicBreadCrumb(state, data) {
        state.classicBreadCrumb = data
    },

    // pushClassicBreadCrumb(state, data) {
    //     state.classicBreadCrumb.push(...data)
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
