<template>
    <section class="rgpd-modal" ref="modal">
        <div class="choices">
            <div class="choice" @click="selected = rgpdType.RESIDENT">
                {{ $t(`residencesList.modal.rgpd.${rgpdType.RESIDENT}`) }}
                <input
                    type="radio"
                    name="residenceType"
                    v-model="selected"
                    :value="rgpdType.RESIDENT"
                />
            </div>
            <div class="choice" @click="selected = rgpdType.TERTIARY">
                {{ $t(`residencesList.modal.rgpd.${rgpdType.TERTIARY}`) }}
                <input
                    type="radio"
                    name="residenceType"
                    v-model="selected"
                    :value="rgpdType.TERTIARY"
                />
            </div>
            <div class="choice" @click="selected = rgpdType.PROVIDER">
                {{ $t(`residencesList.modal.rgpd.${rgpdType.PROVIDER}`) }}
                <input
                    type="radio"
                    name="residenceType"
                    v-model="selected"
                    :value="rgpdType.PROVIDER"
                />
            </div>
            <div
                class="choice"
                @click="selected = rgpdType.CUSTOM"
                v-if="parseInt(simple) !== parseInt(residenceType.SIMPLIFIED)"
            >
                {{ $t(`residencesList.modal.rgpd.${rgpdType.CUSTOM}`) }}
                <input
                    type="radio"
                    name="residenceType"
                    v-model="selected"
                    :value="rgpdType.CUSTOM"
                />
            </div>
        </div>
        <div class="details">
            <span v-if="selected !== null">
                {{ $t(`residencesList.modal.rgpd.detail-${selected}`) }}
            </span>
            <div v-if="selected === rgpdType.RESIDENT">
                <img
                    src="../../assets/images/icons/news.png"
                    width="100"
                    alt="resident is cool"
                />
            </div>
            <div
                v-if="
                    selected === rgpdType.CUSTOM &&
                    parseInt(simple) !== parseInt(residenceType.SIMPLIFIED)
                "
            >
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                {{ $t(`residencesList.modal.rgpd.cage-name`) }}
                            </th>
                            <th class="mobile">
                                {{
                                    $t(
                                        `residencesList.modal.rgpd.${rgpdType.RESIDENT}`
                                    )
                                }}
                            </th>
                            <th class="mobile">
                                {{
                                    $t(
                                        `residencesList.modal.rgpd.${rgpdType.TERTIARY}`
                                    )
                                }}
                            </th>
                            <th class="mobile">
                                {{
                                    $t(
                                        `residencesList.modal.rgpd.${rgpdType.PROVIDER}`
                                    )
                                }}
                            </th>
                            <th class="only-mobile">
                                {{ $t(`residencesList.modal.rgpd.title`) }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(cage, index) in cageList"
                            :key="`key_cage_${cage.id}`"
                        >
                            <td class="name">{{ cage.nom }}</td>
                            <td
                                class="center mobile"
                                v-for="(option, optionIndex) in rgpdOption"
                                :key="`key_cage_option_${optionIndex}`"
                            >
                                <input
                                    type="radio"
                                    :name="`cage_choice_${cage.id}`"
                                    v-model="cageList[index].cnil"
                                    :value="option.value"
                                />
                            </td>
                            <td class="only-mobile">
                                <basic-select
                                    v-model="cageList[index].cnil"
                                    :options="rgpdOption"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination
                    v-show="totalPage > 1"
                    :pagesCount="totalPage"
                    :page="currentPage"
                />
            </div>
            <basic-button @click="saveRgpd">{{
                $t("button.register")
            }}</basic-button>
        </div>
    </section>
</template>

<script>
import { rgpdType, residenceType } from "@/enums"
import {
    getCages,
    saveCageRGPD,
    updateRgpd,
} from "@/services/intratone/residence"
import BasicButton from "@/components/basic/BasicButton.vue"
import Pagination from "@/components/basic/Pagination.vue"
import { useToast } from "vue-toastification"
import BasicSelect from "@/components/basic/BasicSelect.vue"
const toast = useToast()
export default {
    name: "RGPD",
    components: {
        BasicSelect,
        Pagination,
        BasicButton,
    },
    props: {
        rgpdStatus: {
            type: String,
            default: null,
        },
        simple: {
            type: String,
            default: "",
        },
        after: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            rgpdType,
            selected: this.rgpdStatus,
            currentPage: 1,
            totalPage: 1,
            cageList: [],
            residenceType,
            rgpdOption: [],
        }
    },
    mounted() {
        this.getData()
        this.deleteTitle()
    },
    methods: {
        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
        getData() {
            if (parseInt(this.simple) !== parseInt(residenceType.SIMPLIFIED)) {
                getCages({
                    idres: this.$route.params.id,
                    page: this.currentPage,
                    limit: 10,
                    ghost: 0,
                }).then((response) => {
                    if (response.error === 0) {
                        this.currentPage = response.data._page
                        this.totalPage = response.data._pages
                        this.cageList = response.data.list
                    }
                })
                this.rgpdOption = [
                    {
                        value: rgpdType.RESIDENT,
                        name: this.$t(
                            `residencesList.modal.rgpd.${rgpdType.RESIDENT}`
                        ),
                    },
                    {
                        value: rgpdType.TERTIARY,
                        name: this.$t(
                            `residencesList.modal.rgpd.${rgpdType.TERTIARY}`
                        ),
                    },
                    {
                        value: rgpdType.PROVIDER,
                        name: this.$t(
                            `residencesList.modal.rgpd.${rgpdType.PROVIDER}`
                        ),
                    },
                ]
            }
        },
        async saveRgpd() {
            await updateRgpd(this.$route.params.id, {
                rgpd: this.selected,
            })
                .then((response) => {
                    if (response.error === 0) {
                        toast.success(this.$t("alerts.residence.rgpd"), {
                            icon: "fas fa-user-lock",
                        })
                        this.after(this.selected)
                    }
                })
                .finally(() => this.closeModal())
            if (this.selected === rgpdType.CUSTOM) {
                let list = []
                this.cageList.forEach((e) => {
                    list.push({
                        id: e.id,
                        rgpd: e.cnil,
                    })
                })
                await saveCageRGPD({ list })
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
    .only-mobile {
        display: flex;
    }
}
@media all and (min-width: 1024px) {
    .only-mobile {
        display: none;
    }
}
.btn-style {
    padding: 20px;
}
td.center {
    text-align: center;
}
.rgpd-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    .choices {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 20px;
        .choice {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            width: 100px;
            padding: 5px;
            cursor: pointer;
            border: solid 1px $blue-neutral;
            background-color: $light-grey;
            border-radius: 5px;
        }
    }
    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 20px;
        @media all and (min-width: 1024px) {
            padding: 20px 100px;
        }
        @media all and (max-width: 1024px) {
            padding: 20px 5px;
        }
    }
}
</style>
