<template>
    <div class="main-container">
        <div class="title-wrapper" @click="showContent = !showContent">
            <h1>{{ title }}</h1>
            <i
                :class="iconToShow"
                :style="{
                    transform: showContent ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
            ></i>
        </div>
        <div
            class="content-dropdown"
            :class="{
                'show-dropdown': showContent,
            }"
        >
            <div v-for="item in multiContent" :key="item.title">
                <router-link
                    v-if="item.path"
                    :to="item.path"
                    class="dropdown-item"
                    :data-cy="`${dataCy}_${item.title}`"
                    :id="`${id}_${item.title}`"
                    @click="setTab(item.tab, true)"
                >
                    {{ item.title }}
                </router-link>
                <a
                    v-else
                    class="dropdown-item"
                    :href="item.url"
                    :target="target"
                >
                    {{ item.title }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showContent: false,
        }
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        iconToShow: {
            type: String,
            default: "fas fa-chevron-down",
        },
        multiContent: {
            type: Array,
            default: () => [],
        },
        dataCy: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        target: {
            type: String,
            default: null,
        },
        dropdownSize: {
            type: String,
            default: null,
            required: true,
        },
    },
    methods: {
        setTab(tab, value) {
            this.$emit("setTab", tab, value)
        },
        fixDropdownSize(size) {
            document.documentElement.style.setProperty(
                "--dropdown-height",
                size
            )
        },
    },
    mounted() {
        this.fixDropdownSize(this.dropdownSize)
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/global.scss";

.main-container {
    font-family: $font_avenir_heavy;
    color: $blue-neutral;

    .title-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media all and (min-width: 425px) {
            justify-content: start;
            gap: 1em;
        }

        i {
            color: $orange-neutral;
            transition: all 0.2s ease-in-out;
        }
        h1 {
            font-size: $big;
            margin: 0;
        }
    }

    .content-dropdown {
        font-size: $normal;
        font-family: $font-avenir-roman;
        padding-left: 1em;
        display: flex;
        flex-direction: column;
        gap: 1em;
        transition: all 0.2s ease-in-out;
        overflow: hidden;
        height: 0;

        @media all and (min-width: 425px) {
            padding-left: 3em;
        }
    }

    .show-dropdown {
        height: var(--dropdown-height);
        transition: all 0.2s ease-in-out;
        padding-top: 1em;
        padding-bottom: 1em;
    }
}
</style>
