<template>
    <div :class="'position-' + position.y + '-' + position.x" ref="container">
        <div class="popup-wrapper">
            <div class="popup-message">
                {{ principalText }}
            </div>
            <div class="popup-buttons">
                <BasicButton
                    v-if="leftButton.isActive"
                    class="see-more-button"
                    @click="goTo"
                    >{{ leftButton.text }}</BasicButton
                >
                <BasicButton
                    v-if="rightButton.isActive"
                    class="ignore-button"
                    color="white"
                    @click="showOff"
                    >{{ rightButton.text }}</BasicButton
                >
            </div>
        </div>
        <div class="popup-cross" @click="showOff">x</div>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"

export default {
    name: "popUpCard",
    components: {
        BasicButton,
    },
    props: {
        popup: {
            type: String,
            default: null,
        },
        link: {
            type: String,
            default: null,
        },
        principalText: {
            type: String,
            required: true,
        },
        leftButton: {
            type: Object,
            default: () => ({
                isActive: false,
                text: null,
            }),
            validator: function (value) {
                return (
                    typeof value.isActive === "boolean" &&
                    (typeof value.text === "string" || value.text === null)
                )
            },
        },
        rightButton: {
            type: Object,
            default: () => ({
                isActive: false,
                text: null,
            }),
            validator: function (value) {
                return (
                    typeof value.isActive === "boolean" &&
                    (typeof value.text === "string" || value.text === null)
                )
            },
        },
        position: {
            type: Object,
            default: () => ({
                y: "bottom",
                x: "left",
            }),
        },
    },
    methods: {
        showOff() {
            this.$refs.container.classList.add("showOff")
        },
        goTo() {
            switch (this.link) {
                case "notifications":
                    this.$router.push({
                        name: "V1",
                        params: {
                            arg1: "data",
                            arg2: "notifications",
                            arg3: "load",
                        },
                    })
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";
.position-bottom-left {
    animation: slideInBottom 0.8s ease-out, shake 15s ease-in-out infinite 15s;
    animation-delay: shake 2s;
    position: absolute;
    display: flex;
    bottom: 2em;
    padding: 0.5em 1em;
    left: 2em;
    background-color: $white;
    border-radius: 15px;
    color: $blue-dark;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    z-index: 9999;

    .popup-wrapper {
        position: relative;
        padding: 1em 1em;
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .popup-buttons {
            height: auto;
            display: flex;
            gap: 0.8em;
            justify-content: space-between;
            .see-more-button {
                border-radius: 10px;
                height: 2.8em;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-family: $font_avenir_heavy;
            }

            .ignore-button {
                border-radius: 10px;
                height: 2.8em;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-family: $font_avenir_heavy;
                color: $blue-neutral;
                border-color: $blue-neutral;
            }
        }
    }

    .popup-cross {
        border-radius: 99px;
        width: max-content;
        height: max-content;
        padding: 0 0.5em;
        font-size: $big;

        &:hover {
            cursor: pointer;
        }
    }
}

.showOff {
    animation: slideOutBottom 0.5s ease-in;
    animation-fill-mode: forwards;
}
</style>
