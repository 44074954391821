export default [
    {
        path: "/v1/:arg1(data|print)/:arg2/:arg3?/:arg4?/:arg5?/:arg6?",
        name: "V1",
        meta: {
            layout: "PrivateLayout",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/centrales",
        name: "V1Central",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_RESIDENCES",
                "G_KEYPASS",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_CODE_CLAV",
                "G_CODE_TEL",
                "G_AUTOS",
                "G_HORAIRES",
                "UP_VIGIKPROPASS",
            ],
            tab: "central",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/pass/search",
        name: "V1Pass",
        meta: {
            layout: "PrivateLayout",
            authorize: ["G_CLEPASS"],
            tab: "pass",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/communication/actus/list",
        name: "V1BulletinBoard",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_TABL_VISU",
                "G_TABL_COMMG",
                "G_TABL_ACTUS",
                "G_TABL_GERE",
                "G_TABL_MESSAGE",
            ],
            tab: "noticeboard",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/XmlToHtml/xml=<LesPlagesH§>&xsl=data§plages.xsl",
        name: "V1TimeSlot",
        meta: {
            layout: "PrivateLayout",
            authorize: ["G_HORAIRES"],
            tab: "timerange",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/search/load",
        name: "V1ManageSearch",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
            ],
            tab: "search",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/sms/init=1",
        name: "V1SMS",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/pcetape1",
        name: "V1Programmation",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/programmation/programmateur/etape1",
        name: "V1Programmation2",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/vigik/vigiks/id=0",
        name: "V1Vigik",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/propass",
        name: "V1Propass",
        meta: {
            layout: "PrivateLayout",
            authorize: ["UP_VIGIKPROPASS"],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/boiteacle/boite/list",
        name: "V1KeySafe",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/Html/notices",
        name: "V1Help",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
    {
        path: "/v1/data/dust",
        name: "V1Trash",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/V1.vue"),
    },
]
