<template>
    <div class="lang-menu dropdown">
        <button
            class="lang-menu-button dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            <a
                v-if="selectedLang"
                class="selected-lang dropdown-item lang"
                href=""
            >
                <img
                    class="flag-image"
                    width="39"
                    height="24"
                    :src="selectedLang.img"
                />{{ selectedLang.name }}
            </a>
        </button>
        <ul
            class="lang-menu-list dropdown-menu"
            aria-labelledby="lang-menu-button"
        >
            <li v-for="lang in langs" :key="lang.code">
                <a
                    class="dropdown-item lang"
                    @click="changeLanguage(lang.code)"
                >
                    <img
                        class="flag-image"
                        width="39"
                        height="24"
                        :src="lang.img"
                    />{{ lang.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "LanguageSelector",
    data() {
        return {
            langs: [
                {
                    name: "France",
                    code: "fr",
                    img: require("@/assets/images/flags/fr.png"),
                },
                {
                    name: "United Kingdom",
                    code: "en",
                    img: require("@/assets/images/flags/en.png"),
                },
                {
                    name: "Suisse (Français)",
                    code: "ch-fr",
                    img: require("@/assets/images/flags/ch.gif"),
                },
                {
                    name: "Schweiz (Deutsch)",
                    code: "ch-de",
                    img: require("@/assets/images/flags/ch.gif"),
                },
                {
                    name: "Danmark",
                    code: "dk",
                    img: require("@/assets/images/flags/dk.png"),
                },
                {
                    name: "Suomi",
                    code: "fi",
                    img: require("@/assets/images/flags/fi.png"),
                },
                {
                    name: "Deutschland",
                    code: "de",
                    img: require("@/assets/images/flags/de.png"),
                },
                {
                    name: "Belgique (Français)",
                    code: "be-fr",
                    img: require("@/assets/images/flags/be.png"),
                },
                {
                    name: "België (Vlaams)",
                    code: "be-fl",
                    img: require("@/assets/images/flags/be.png"),
                },
                {
                    name: "Luxembourg (Français)",
                    code: "lu-fr",
                    img: require("@/assets/images/flags/lu.png"),
                },
                {
                    name: "Luxemburg (Deutsch)",
                    code: "lu-de",
                    img: require("@/assets/images/flags/lu.png"),
                },
                {
                    name: "Norge",
                    code: "no",
                    img: require("@/assets/images/flags/no.png"),
                },
                {
                    name: "Nederland",
                    code: "nl",
                    img: require("@/assets/images/flags/nl.png"),
                },
                {
                    name: "Sverige",
                    code: "se",
                    img: require("@/assets/images/flags/se.png"),
                },
                {
                    name: "España",
                    code: "es",
                    img: require("@/assets/images/flags/es.png"),
                },
            ],
            selectedLang: null,
        }
    },
    methods: {
        //  Change route (current route with new lang parameter)
        changeLanguage(langCode) {
            this.$store.commit("account/setLanguage", langCode)
            this.setSelectedLanguage()
            window.location.href =
                window.location.origin + `/${langCode}/` + this.$route.href
        },
        // Set selected language in dropdown
        setSelectedLanguage() {
            let language = this.$store.getters["account/getLanguage"]
            language = language !== null ? language : "fr"
            for (const lang in this.langs) {
                if (this.langs[lang].code === language) {
                    this.selectedLang = this.langs[lang]
                }
            }
        },
    },
    created() {
        this.setSelectedLanguage()
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";

.lang-menu-button {
    border: none;
    background: none;
}

.lang-menu-list {
    inset: 0px auto auto -20px !important;
}

.lang-menu-list li {
    cursor: pointer;
}

.selected-lang {
    border-radius: 15px;
    border: 1px solid $grey;
}

.lang {
    padding: 8px 20px 8px 20px;
    display: flex;
    align-items: center;
}

.flag-image {
    margin-right: 12px;
}
</style>
