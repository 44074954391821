import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import auth from "./modules/auth"
import account from "./modules/account"
import breadCrumb from "./modules/breadCrumb"
import doubleAuth from "./modules/doubleAuth"
import images from "./modules/images"
import miscellaneous from "./modules/miscellaneous"
import residences from "./modules/residences"
import mutations from "./mutations"
import updateProvidersStatus from "./modules/updateProvidersStatus"
const LOCAL_STORAGE_KEY = process.env.VITE_LOCAL_STORAGE_KEY

// enable debug if is not for production
const debug = process.env.NODE_ENV !== "production"

// Create a new store instance.
const store = createStore({
    // force use of mutation to update states if not in production - Do not enable strict mode when deploying for production! Strict mode runs a synchronous deep watcher on the state tree for detecting inappropriate mutations,
    // and it can be quite expensive when you make large amount of mutations to the state. Make sure to turn it off in production to avoid the performance cost. (https://next.vuex.vuejs.org/guide/strict.html)
    strict: debug,
    modules: {
        auth,
        account,
        breadCrumb,
        doubleAuth,
        images,
        miscellaneous,
        updateProvidersStatus,
        residences,
    },
    mutations,
    plugins: [
        createPersistedState({
            key: LOCAL_STORAGE_KEY,
            paths: ["auth", "account", "breadCrumb"],
        }),
    ],
})

export default store
