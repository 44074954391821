import Vue from "vue"

const state = () => ({
    providers: [],
})

const getters = {
    /**
     * Return only visible providers
     * @param states
     * @returns {T[]}
     */
    visibleList: (states) => {
        return states.providers.filter((provider) => {
            return provider.isVisible !== false
        })
    },
    /**
     * Return only modified providers
     * @param states
     * @returns {T[]}
     */
    modifiedList: (states) => {
        return states.providers.filter((provider) => {
            return provider.level !== provider.originalLevel
        })
    },
    thereAreCustomProviders: (states) => {
        return states.providers.some(
            (provider) => provider.level === "WHITELIST"
        )
    },
}

const mutations = {
    SET_PROVIDERS(state, providers) {
        state.providers = providers
    },
    UPDATE_PROVIDERS(state, newProviders) {
        // will store indices to remove
        const indicesToRemove = []
        // foreach current provider
        for (
            let providerIndex = 0;
            providerIndex < state.providers.length;
            providerIndex++
        ) {
            const index = newProviders.findIndex((newProviders) => {
                return newProviders.id === state.providers[providerIndex].id
            })
            // if provider is not in the provider received list
            if (index === -1) {
                // if provider has been modified
                if (
                    state.providers[providerIndex].level !==
                    state.providers[providerIndex].originalLevel
                ) {
                    state.providers[providerIndex].isVisible = false
                } else {
                    // state.providers.splice(providerIndex, 1)
                    indicesToRemove.push(providerIndex)
                }
            }
        }

        // Remove elements from the original array in reverse order
        indicesToRemove
            .sort((a, b) => b - a)
            .forEach((index) => state.providers.splice(index, 1))

        // foreach provider received
        newProviders.forEach((newProvider) => {
            const index = state.providers.findIndex((provider) => {
                return newProvider.id === provider.id
            })
            // if provider not already exist
            if (index === -1) {
                state.providers.push(newProvider)
            } else {
                if (state.providers[index].isVisible === false) {
                    state.providers[index].isVisible = true
                }
            }
        })
    },
    UPDATE_PROVIDER_LEVEL(state, { providerId, level }) {
        const provider = state.providers.find(
            (provider) => provider.id === providerId
        )
        provider.level = level
    },
    RESET_PROVIDER_LEVEL(state, providerId) {
        const provider = state.providers.find(
            (provider) => provider.id === providerId
        )
        provider.level = provider.originalLevel
    },
    RESET_ALL_PROVIDER_LEVEL(state) {
        for (let i = 0; i < state.providers.length; i++) {
            state.providers[i].level = state.providers[i].originalLevel
        }
    },
}

const actions = {
    /**
     * Set providers will override current providers
     * @param commit
     * @param providers
     */
    setProviders({ commit }, providers) {
        storeProvidersOriginalState(providers)
        commit("SET_PROVIDERS", providers)
    },
    /**
     * Update providers will keep existing providers
     * - if provider exist in current and new providers we keep current provider
     * - if provider not exist in current we remove it
     * - if provider exist in current but not in new we set is visibility to false
     * @param commit
     * @param providers
     */
    updateProviders({ commit }, providers) {
        storeProvidersOriginalState(providers)
        commit("UPDATE_PROVIDERS", providers)
    },
    updateProviderLevel({ commit }, { providerId, level }) {
        commit("UPDATE_PROVIDER_LEVEL", { providerId, level })
    },
    /**
     * Reset provider level will update is current level with the value of the original one
     * @param commit
     * @param providerId
     */
    resetProviderLevel({ commit }, providerId) {
        commit("RESET_PROVIDER_LEVEL", providerId)
    },
    /**
     * Reset all providers level will update the current level with the value of the original one foreach provider
     * @param commit
     */
    resetAllProviderLevel({ commit }) {
        commit("RESET_ALL_PROVIDER_LEVEL")
    },
}

/**
 * Copy original state for a list of providers inside provider.originalState
 * @param {[provider]} providers
 */
function storeProvidersOriginalState(providers) {
    for (let i = 0; i < providers.length; i++) {
        providers[i].originalLevel = providers[i].level
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
