<template>
    <!-- Before : this pagination, but with this method if there are many number, the numbers overflow  -->
    <!--    <div class="pagination">-->
    <!--            <div-->
    <!--                class="page-number"-->
    <!--                @click="setPage(currentPage - 1)"-->
    <!--            >-->
    <!--                <i class="fas fa-chevron-left"></i>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                class="page-number"-->
    <!--                :class="{ active: index === currentPage}"-->
    <!--                v-for="index in pagesCount"-->
    <!--                :key="index"-->
    <!--                @click="setPage(index)"-->
    <!--            >-->
    <!--                <span>{{ index }}</span>-->
    <!--            </div>-->
    <!--            <div-->
    <!--                class="page-number"-->
    <!--                @click="setPage(currentPage + 1)"-->
    <!--            >-->
    <!--                <i class="fas fa-chevron-right"></i>-->
    <!--            </div>-->
    <!--        </div>-->

    <!-- After : This pagination add three point if there are many numbers for get good display -->
    <!-- PAGINATION -->
    <div class="pagination" v-show="pagesCount > 1">
        <div class="page-number" @click="setPage(currentPage - 1)">
            <i class="fas fa-chevron-left"></i>
        </div>
        <div v-for="(page, index) in pagesCount" :key="index + '_pagination'">
            <span
                class="page-number"
                v-if="
                    (index + 1 <= currentPage + 2 &&
                        index + 1 >= currentPage - 2) ||
                    index === 0 ||
                    index + 1 === pagesCount
                "
                :class="{ active: index === currentPage - 1 }"
                @click="setPage(page)"
            >
                <span
                    role="button"
                    href="#"
                    :aria-current="{ true: page === currentPage }"
                    class="pagination-link"
                    :class="{ 'is-current': page === currentPage }"
                >
                    {{ page }}
                </span>
            </span>
            <span
                class="page-number-etc"
                v-else-if="index === 1 || index === pagesCount - 2"
            >
                ...
            </span>
        </div>
        <div class="page-number" @click="setPage(currentPage + 1)">
            <i class="fas fa-chevron-right"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        pagesCount: {
            type: Number,
            default: 1,
        },
        page: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            currentPage: 1,
        }
    },
    watch: {
        page: {
            immediate: true,
            handler(page) {
                this.currentPage = page
            },
        },
    },
    mounted() {
        this.currentPage = this.page
    },
    methods: {
        setPage(page) {
            if (page < 1) {
                page = 1
            } else if (page > this.pagesCount) {
                page = this.pagesCount
            }
            this.currentPage = page
            this.$emit("new-current-page", this.currentPage)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.pagination {
    display: flex;
    width: fit-content;
    margin: 30px auto 0 auto;

    .page-number {
        width: 28px;
        height: 28px;
        font-size: $normal;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        transition: background-color 200ms, color 200ms;
    }
    .page-number-etc {
        width: 28px;
        height: 28px;
        font-size: $normal;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        cursor: default;
    }

    .page-number:hover {
        background-color: $grey-light;
        cursor: pointer;
        color: $orange-neutral;
    }

    .active {
        background-color: $blue-light;
        color: $white;
    }

    .active:hover {
        background-color: $blue-light;
        color: $white;
    }
}
</style>
