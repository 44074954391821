export default [
    {
        path: "/login",
        name: "Login",
        meta: {
            layout: "PublicLayout",
        },
        component: () => import("@/views/login/Login.vue"),
    },
    {
        path: "/logout",
        name: "Logout",
        meta: {
            layout: "PublicLayout",
        },
        component: () => import("@/views/auth/Logout.vue"),
    },
    {
        path: "/renew",
        name: "Renew",
        meta: {
            layout: "PrivateLayout",
        },
        component: () => import("@/views/auth/Renew.vue"),
    },
    {
        path: "/console",
        name: "ConsoleLogin",
        meta: {
            layout: "PublicLayout",
        },
        component: () => import("@/views/login/Console.vue"),
    },
    {
        path: "/register",
        name: "Register",
        meta: {
            layout: "PublicLayout",
        },
        component: () => import("@/views/login/Register.vue"),
    },
    {
        path: "/forgotten-password",
        name: "ForgottenPassword",
        meta: {
            layout: "PublicLayout",
        },
        component: () => import("@/views/login/ForgottenPassword.vue"),
    },
    {
        path: "/support",
        name: "Support",
        meta: {
            layout: "PublicLayout",
        },
        component: () => import("@/views/login/Support.vue"),
    },
]
