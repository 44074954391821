export function getEnvironment() {
    switch (window.location.hostname) {
        case "localhost":
        case "127.0.0.1":
        case "dev.intratone.info":
            return "dev"
        case "test.intratone.info":
            return "test"
        case "salon.intratone.info":
            return "salon"
        case "demo.intratone.info":
            return "demo"
        default:
            return "prod"
    }
}
