<template>
    <div class="chip-container" :class="'bg-' + background">
        <div v-if="pinColor" :class="'pin-' + pinColor" />
        <span :class="'font-' + font">{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: "BasicChip",
    props: {
        text: {
            type: String,
            default: null,
        },

        pinColor: {
            type: String,
            default: null,
        },

        background: {
            type: String,
            default: "grey",
        },
        font: {
            type: String,
            default: "heavy",
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
span {
    font-size: $small;
    font-family: $font_avenir_heavy;
}
.chip-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 0.3em 0.8em;
    border-radius: 30px;
    gap: 0.5em;
    font-size: $small;
    text-wrap: nowrap;

    .pin-blue-teal {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $blue-teal;
    }
    .pin-blue {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $blue-neutral;
    }

    .pin-orange {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $orange-neutral;
    }
}

.bg-grey {
    background-color: $grey-lighter;
}

.bg-blue-powder {
    background-color: $blue-powder;
    color: $blue-bright;
}

.bg-dark-blue {
    background-color: $blue-powder;
    color: $blue-neutral;
}

//font
.font-heavy {
    font-family: $font_avenir_heavy;
}

.font-roman {
    font-family: $font-avenir-roman;
}
</style>
