<template>
    <div class="double-auth-container" ref="modal">
        <p>{{ $t("global.modal.doubleAuth.text") }}</p>
        <p>{{ email }}</p>
        <div class="input-wrapper">
            <p>{{ $t("global.modal.doubleAuth.validationCode") }}</p>
            <BasicInput
                name="codeValidation"
                placeholder="123456"
                class="input"
                v-model="code"
            />
        </div>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
        <div class="button-wrapper">
            <BasicButton
                :disabled="loading || !code"
                :color="loading || !code ? 'grey' : 'orange'"
                @click="loading ? null : validCode()"
                v-if="!closeModal"
            >
                {{ $t("button.validate") }}
            </BasicButton>
            <BasicButton color="blue" @click="refuse" v-if="!closeModal">
                {{ $t("button.cancel") }}
            </BasicButton>
            <BasicButton v-if="closeModal" @click="close">
                {{ $t("button.close") }}
            </BasicButton>
        </div>
    </div>
</template>

<script>
import BasicInput from "@/components/basic/BasicInput.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
export default {
    name: "DoubleAuth",
    components: { BasicInput, BasicButton },
    props: {
        email: {
            type: String,
            default: null,
        },
        valid: {
            type: Function,
            default: null,
        },
        refuse: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            code: null,
            disable: true,
            loading: false,
        }
    },
    mounted() {
        this.deleteTitle()
    },
    computed: {
        errorMessage() {
            return this.$store.getters["doubleAuth/getErrorMessage"]
        },

        isDisable() {
            if (
                this.errorMessage ===
                this.$t("global.modal.doubleAuth.error.retry")
            ) {
                return true
            } else {
                return false
            }
        },

        closeModal() {
            if (
                this.errorMessage ===
                    this.$t("global.modal.doubleAuth.error.expired") ||
                this.errorMessage ===
                    this.$t("global.modal.doubleAuth.error.retry")
            ) {
                return true
            }

            return false
        },
    },
    methods: {
        async validCode() {
            this.loading = true
            await this.valid(this.code)
            this.loading = false
        },

        close() {
            if (this.refuse) {
                this.refuse()
            }
            this.closeModal("double-auth")
        },

        deleteTitle() {
            if (this.$refs.modal.getAttribute("title")) {
                this.$refs.modal.removeAttribute("title")
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";
.double-auth-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-top: 1em;
    font-family: $font-avenir-medium;

    p {
        margin: 0;
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        gap: 2em;

        p {
            text-wrap: nowrap;
        }

        .input {
            width: 100%;
        }
    }

    .error {
        color: $pink;
        font-family: $font-avenir-medium;
        font-size: $small;
    }

    .button-wrapper {
        padding-top: 0.5em;
        display: flex;
        justify-content: center;
        gap: 1em;
    }
}
</style>
