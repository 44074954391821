export default [
    {
        path: "/vigik3",
        name: "Vigik3",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/Vigik3.vue"),
    },
    {
        path: "/vigik3/create-access-type",
        name: "V3CreateProfil",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/accessType/CreateProfil.vue"),
    },
    {
        path: "/vigik3/custom-access-type/:id/:vigikName/:isVigik/:selectedTab?/:searchTerm?",
        name: "CustomAccessType",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/activity/ActivityHomeView.vue"),
    },
    {
        path: "/vigik3/access-type/:accessId/activity/:activityId/:activityAllow/schedule/:activityName/:scheduleId/:isPersonnalised",
        name: "ActivitySchedule",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/ActivitySchedule.vue"),
    },
    {
        path: "/vigik3/access-type/:accessId/provider/:providerId/schedule/:providerName/:scheduleId/:isPersonnalised",
        name: "ProviderSchedule",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/ProviderSchedule.vue"),
    },
    {
        path: "/vigik3/access-type/:accessId/activity/:activityId/:activityStatus/providers/:activityName",
        name: "ActivityProviders",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () =>
            import("@/views/vigik/providers/ProvidersHomeView.vue"),
    },
    {
        path: "/vigik3/access-type/:id/access-list/:vigikName",
        name: "AccessList",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/AccessList.vue"),
    },
    {
        path: "/vigik3/access-type/:serviceId/:serviceName/origin",
        name: "VigikUpdate",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/UpdateAccessActivity.vue"),
    },
    {
        path: "/vigik3/access-type/:serviceId/:serviceName/origin/:originId",
        name: "UpdateProvider",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/UpdateAccessProvider.vue"),
    },
    {
        path: "/vigik3/access-type/:accessId/:providerName/:providerId/UpdateSchedule/origin/:originId",
        name: "UpdateScheduleProvider",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/UpdateScheduleProvider.vue"),
    },
    {
        path: "/vigik3/access-type/:accessId/activity/:activityId/UpdateSchedule/:activityName",
        name: "UpdateScheduleActivity",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/UpdateScheduleActivity.vue"),
    },
    {
        path: "/vigik3/access-type/:id/update-access-infos",
        name: "VigikUpdateAccessTypeInfos",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/vigik/accessType/UpdateInfos.vue"),
    },
]
