<template>
    <div class="navigation-block" v-show="showNavigationBar">
        <div class="navigation-part-wrapper">
            <div class="navigation-left-part">
                <ul class="navigation-list">
                    <basic-button class="burger-menu">
                        <i
                            class="fas fa-bars"
                            @click="$emit('setOpenMobile', true)"
                        ></i>
                    </basic-button>
                    <div class="logo" @click="goToHome()">
                        <img
                            src="../assets/images/intratone/logo-wo.png"
                            alt="logo-intratone"
                        />
                    </div>
                    <!-- preloading background in order to prevent blinking -->
                    <div class="preload">
                        <img
                            src="../assets/images/intratone/logo-wot.png"
                            alt="logo-intratone"
                        />
                    </div>
                    <div
                        class="list-container"
                        :class="isOpenMobile === true && 'open'"
                    >
                        <li
                            v-for="(item, index) in authorizedLinks"
                            :key="item.title"
                            class="navigation-item"
                            :class="{
                                isActive: currentTab == item.tab,
                            }"
                            :data-cy="`navigation_cy_${item.tab}`"
                            :id="`navigation_${item.title}`"
                            @click="setTab(item.tab)"
                        >
                            <div
                                v-if="item.sublinks"
                                class="navigation-dropdown"
                            >
                                <span
                                    class="dropdown-toggle"
                                    :class="'navigation-dropdown-' + index"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    {{ item.title }}
                                </span>
                                <ul
                                    class="dropdown-menu"
                                    :aria-labelledby="
                                        'navigation-dropdown-' + index
                                    "
                                >
                                    <li
                                        v-for="sublink in authorizedSublinks"
                                        :key="sublink.title"
                                    >
                                        <router-link
                                            v-if="sublink.path"
                                            :to="sublink.path"
                                            class="dropdown-item"
                                            :data-cy="`sub_navigation_cy_${sublink.tab}`"
                                            :id="`sub_navigation_${sublink.title}`"
                                            @click="setTab(item.tab, true)"
                                        >
                                            {{ sublink.title }}
                                        </router-link>
                                        <a
                                            v-else
                                            class="dropdown-item"
                                            :href="sublink.url"
                                            target="_blank"
                                        >
                                            {{ sublink.title }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <router-link v-else :to="item.path">
                                {{ item.title }}
                            </router-link>
                        </li>
                    </div>
                </ul>
            </div>
            <div class="navigation-right-part">
                <basic-button
                    @click="goToAccount(), setTab('account'), closeMenu()"
                    class="account-button btn"
                    :class="{ isActive: currentTab == 'account' }"
                    color="transparent"
                    :title="userLogin"
                >
                    <img :src="require('@/assets/images/icons/profile.png')" />
                    <span id="username">{{
                        $store.getters["account/getFullName"]
                    }}</span>
                </basic-button>
                <basic-button
                    class="search-button btn"
                    :class="{ isActive: currentTab == 'home' }"
                    color="transparent"
                    @click="goToHome(), setTab('home'), closeMenu()"
                >
                    <i class="fas fa-search"></i>
                </basic-button>
                <basic-button
                    class="notifications-button btn"
                    :class="{ isActive: currentTab == 'notif' }"
                    :badgeNumber="userNotifications"
                    color="transparent"
                    @click="goToNotifications(), setTab('notif'), closeMenu()"
                >
                    <i class="fas fa-bell"></i>
                </basic-button>
                <basic-button
                    class="disconnect-button btn"
                    color="transparent"
                    @click="deconnect(), closeMenu()"
                >
                    <i class="fas fa-power-off"></i>
                </basic-button>
            </div>
        </div>
    </div>
    <div class="mobile-navigation-block">
        <div class="visible-menu" ref="visibleMenu">
            <div class="left-part-menu" @click="goToHome()">
                <img src="@/assets/images/intratone/logo-wo.png" alt="" />
            </div>
            <div class="right-part-menu">
                <div
                    class="search"
                    @click="goToHome(), setTab('home'), closeMenu()"
                >
                    <i class="fa fa-search" />
                </div>
                <div
                    class="notifications"
                    @click="goToNotifications(), setTab('notif'), closeMenu()"
                >
                    <div
                        v-if="userNotifications"
                        class="number-of-notifications"
                        :badgeNumber="userNotifications"
                    >
                        {{ userNotifications }}
                    </div>
                    <i class="fa fa-bell" />
                </div>
                <div class="menu">
                    <Transition mode="out-in" name="fade">
                        <div
                            class="stroke-wrapper"
                            ref="strokeWrapper"
                            @click="openMenu"
                            v-if="!isOpenMobile"
                        >
                            <div class="stroke-top" ref="strokeTop"></div>
                            <div class="stroke-middle" ref="strokeMiddle"></div>
                            <div class="stroke-bottom" ref="strokeBottom"></div>
                        </div>
                        <div
                            class="cross-wrapper"
                            ref="crossWrapper"
                            @click="closeMenu"
                            v-else
                        >
                            <i class="fa fa-times"></i>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
        <Transition mode="out-in" name="fade">
            <div class="unvisible-menu-wrapper" v-show="isOpenMobile">
                <div class="unvisible-menu" ref="unvisibleMenu">
                    <div class="content">
                        <div
                            v-for="item in authorizedLinks"
                            :key="item.title"
                            class="navigation-item"
                            :class="{
                                isActive: currentTab == item.tab,
                            }"
                            :data-cy="`navigation_cy_${item.title}`"
                            :id="`navigation_${item.title}`"
                            @click="item.sublinks ? null : setTab(item.tab)"
                        >
                            <div
                                v-if="item.sublinks"
                                class="navigation-dropdown"
                            >
                                <!-- Make a dropdown which takes all the available sublinks with dropdownSize-->
                                <BasicAccordion
                                    :title="item.title"
                                    :multiContent="item.sublinks"
                                    dataCy="sub_navigation_cy"
                                    id="sub_navigation"
                                    target="_blank"
                                    :dropdownSize="dropdownSize(item.sublinks)"
                                    @setTab="setTab"
                                />
                            </div>
                            <router-link v-else :to="item.path" class="link">
                                {{ item.title }}
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="account"
                        @click="goToAccount(), setTab('account'), closeMenu()"
                    >
                        <span class="fa fa-user"></span>
                        <h1>{{ $store.getters["account/getFullName"] }}</h1>
                    </div>
                    <div class="logout" @click="deconnect()">
                        <span class="fa fa-power-off"></span>
                        <h1>{{ $t("login.disconnect") }}</h1>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"
import BasicAccordion from "./basic/BasicAccordion.vue"
import notices from "@/mixins/redirection.js"
import { getNotifications } from "@/services/intratone/account"
import { useToast } from "vue-toastification"
import { nextTick } from "@vue/runtime-core"
import navigation from "@/mixins/navigation"
const toast = useToast()

export default {
    name: "NavigationBar",
    mixins: [notices, navigation],
    components: {
        BasicButton,
        BasicAccordion,
    },
    props: ["isOpenMobile"],
    data() {
        return {
            links: [
                {
                    title: this.$t("navigation.stock"),
                    path: { name: "Residences" },
                    tab: "residences",
                    fct: this.$store.commit("account/setResidenceId", null),
                    rights: [
                        "G_KEY",
                        "G_KEY_MOBIL",
                        "G_CLEPASS",
                        "G_FICHES",
                        "G_APP",
                        "G_APP_NOM",
                        "G_KEY_MOVE",
                        "G_NAME_MOVE",
                        "G_RESIDENCES",
                        "G_KIBOLT",
                        "G_CONNECTIT",
                        "G_CODE_TEL",
                        "G_CODE_CLAV",
                    ],
                    define: ["MENU_CENTRALES"],
                },
                {
                    title: this.$t("navigation.pass"),
                    path: { name: "V1Pass" },
                    tab: "pass",
                    rights: ["G_CLEPASS"],
                    define: [],
                },
                {
                    title: this.$t("navigation.bulletinBoard"),
                    path: { name: "V1BulletinBoard" },
                    tab: "noticeboard",
                    rights: [
                        "G_TABL_VISU",
                        "G_TABL_COMMG",
                        "G_TABL_ACTUS",
                        "G_TABL_GERE",
                        "G_TABL_MESSAGE",
                    ],
                    define: ["MENU_TABLEAUX"],
                },
                {
                    title: this.$t("navigation.timeSlot"),
                    path: { name: "V1TimeSlot" },
                    tab: "timerange",
                    rights: ["G_HORAIRES"],
                    define: ["MENU_PLAGES"],
                },
                {
                    title: this.$t("navigation.manageSearch"),
                    path: { name: "V1ManageSearch" },
                    tab: "search",
                    rights: [
                        "G_KEY",
                        "G_KEY_MOBIL",
                        "G_CLEPASS",
                        "G_FICHES",
                        "G_APP",
                        "G_APP_NOM",
                        "G_KEY_MOVE",
                        "G_NAME_MOVE",
                        "G_RESIDENCES",
                        "G_KIBOLT",
                        "G_CONNECTIT",
                    ],
                    define: [],
                },
                {
                    title: this.$t("navigation.tools"),
                    tab: "outils",
                    sublinks: [
                        {
                            title: this.$t("entityDetails.tabs.centralUnits"),
                            path: { name: "V1Central" },
                            tab: "centrale",
                            rights: [
                                "G_RESIDENCES",
                                "G_KEYPASS",
                                "G_KEY_MOBIL",
                                "G_CLEPASS",
                                "G_FICHES",
                                "G_APP",
                                "G_APP_NOM",
                                "G_CODE_CLAV",
                                "G_CODE_TEL",
                                "G_AUTOS",
                                "G_HORAIRES",
                                "UP_VIGIKPROPASS",
                            ],
                            define: ["MENU_CENTRALES"],
                        },
                        {
                            title: this.$t("navigation.texts"),
                            path: { name: "V1SMS" },
                            tab: "sms",
                            rights: ["G_SMS_SEND"],
                            define: ["MENU_SMS"],
                        },
                        {
                            title: this.$t("navigation.vigik"),
                            path: { name: "V1Vigik" },
                            tab: "vigik",
                            rights: ["UP_VIGIKPROPASS"],
                            define: ["MENU_VIGIK"],
                        },
                        {
                            title: this.$t('navigation.vigik3'),
                            path: { name: 'Vigik3' },
                            rights: ['UP_VIGIKPROPASS'],
                            define: ['MENU_VIGIK'],
                        },
                        {
                            title: this.$t('navigation.propass'),
                            path: { name: 'V1Propass' },
                            rights: ['UP_VIGIKPROPASS'],
                            define: ['MENU_VIGIK']
                        },
                        {
                            title: this.$t("navigation.programmation"),
                            path: { name: "V1Programmation" },
                            tab: "programmation",
                            rights: ["G_KEY"],
                            define: [],
                        },
                        {
                            title: this.$t("navigation.programmation2"),
                            path: { name: "V1Programmation2" },
                            tab: "programmation2",
                            rights: ["G_KEY"],
                            define: ["IP_INTERNE"],
                        },
                        {
                            title: this.$t("navigation.keySafe"),
                            path: { name: "V1KeySafe" },
                            tab: "keySafe",
                            rights: ["G_COFFRES_CLEFS"],
                            define: ["MENU_COFFREACLE"],
                        },
                        {
                            title: this.$t("navigation.provider"),
                            tab: "provider",
                            url:
                                "https://" +
                                window.location.hostname +
                                "/monitor/gestion.php",
                            define: ["MENU_PRESTATAIRE"],
                        },
                        {
                            title: this.$t("navigation.help"),
                            tab: "help",
                            url: this.getNotices(),
                            define: [],
                        },
                        {
                            title: this.$t("navigation.support"),
                            tab: "support",
                            path: { name: "HelpDesk" },
                            rights: [],
                            define: [],
                        },
                        {
                            title: this.$t("navigation.trash"),
                            tab: "trash",
                            path: { name: "V1Trash" },
                            rights: [],
                            define: ["CONSOLE"],
                        },
                    ],
                },
            ],
            showNavigationBar: true,
            showMobileMenu: false,
            showOtherTools: false,
            currentTab: localStorage.getItem("tab"),
        }
    },
    computed: {
        authorizedLinks() {
            const routes = this.$router.getRoutes()
            const linkArray = []
            this.links.forEach((link) => {
                if (link.path) {
                    routes.forEach((e) => {
                        if (e.name === link.path.name) {
                            if (
                                this.checkRightWithOr(link.rights) &&
                                this.checkDefineWithAnd(link.define)
                            ) {
                                linkArray.push(link)
                            }
                        }
                    })
                } else {
                    const sublinkArray = []
                    link.sublinks.forEach((sublink) => {
                        if (sublink.path === undefined) {
                            if (sublink.rights && sublink.define) {
                                if (
                                    this.checkRightWithOr(sublink.rights) &&
                                    this.checkDefineWithAnd(sublink.define)
                                ) {
                                    sublinkArray.push(sublink)
                                }
                            } else if (sublink.rights || sublink.define) {
                                if (
                                    sublink.rights &&
                                    this.checkRightWithOr(sublink.rights)
                                ) {
                                    sublinkArray.push(sublink)
                                } else if (
                                    sublink.define &&
                                    this.checkDefineWithAnd(sublink.define)
                                ) {
                                    sublinkArray.push(sublink)
                                }
                            } else {
                                sublinkArray.push(sublink)
                            }
                        } else {
                            routes.forEach((e) => {
                                if (e.name === sublink.path.name) {
                                    if (
                                        this.checkRightWithOr(sublink.rights) &&
                                        this.checkDefineWithAnd(sublink.define)
                                    ) {
                                        sublinkArray.push(sublink)
                                    }
                                }
                            })
                        }
                    })
                    link.sublinks = sublinkArray
                    linkArray.push(link)
                }
            })
            return linkArray
        },

        authorizedSublinks() {
            const sublinks = this.links.find(link => link.tab === 'outils').sublinks
            sublinks.forEach((sublink) => {
                if (!this.isAuthorizedSublinks(sublink)) {
                    sublinks.splice(sublinks.indexOf(sublink), 1)
                }
            })
            return sublinks
        },
        userNotifications() {
            return this.$store.getters["account/getNotifications"]
        },
        userLogin() {
            return this.$store.getters['account/getLogin']
        },

        userIsFrench() {
            switch (this.$store.getters['account/getCountry']) {
                case '1':
                case '12':
                case '13':
                case '14':
                case '16':
                case '17':
                case '18':
                case '38':
                    return true
                default:
                    return false
            }
        }
    },
    methods: {
        deconnect() {
            this.$router.push({
                name: "Logout",
                query: {
                    call: 1,
                },
            })
        },
        goToAccount() {
            this.$router.push({
                name: "V1",
                params: {
                    arg1: "data",
                    arg2: "gestionnaire",
                    arg3: "list",
                },
            })
        },
        goToHome() {
            this.$router.push({
                name: "V1",
                params: {
                    arg1: "data",
                    arg2: "accueil",
                    arg3: "accueil",
                },
            })
            this.setTab('home')
        },
        goToNotifications() {
            this.$router.push({
                name: "V1",
                params: {
                    arg1: "data",
                    arg2: "notifications",
                    arg3: "load",
                },
            })
        },
        async displayNavigationBar() {
            await nextTick()
            let contentBlock =
                document.getElementsByClassName("content-block")[0] || null
            if (contentBlock !== null) {
                if (this.$route.fullPath.startsWith("/v1/print")) {
                    this.showNavigationBar = false
                    contentBlock.style.height = "100vh"
                } else {
                    this.showNavigationBar = true
                    contentBlock.style.height = "90vh"
                }
            }
        },
        setTab(tab, passOther = false) {
            localStorage.setItem("tab", tab)
            if (tab !== "others" || passOther) {
                this.closeMenu()
            }
        },
        getNotificationsToast() {
            getNotifications().then((response) => {
                this.$store.commit(
                    "account/setNotifications",
                    parseInt(response.data.messages.unread, 10)
                )

                if (
                    this.$store.getters["account/getLastDateNotification"] !==
                        response.data.messages.last_date &&
                    response.data.messages.last_date
                ) {
                    this.$store.commit(
                        "account/setLastDateNotification",
                        response.data.messages.last_date
                    )
                    this.$store.commit("account/setShowNotification", true)
                } else {
                    this.$store.commit("account/setShowNotification", false)
                }
            })
        },
        dropdownSize(array) {
            switch (array.length) {
                case 4:
                    return "9rem"
                case 5:
                    return "11rem"
                case 6:
                    return "13.5rem"
                case 7:
                    return "15.5rem"
                case 8:
                    return "17.5rem"
                case 9:
                    return "20rem"
                case 10:
                    return "22rem"

                default:
                    return "max-content"
            }
        },
        openMenu() {
            this.$emit("setOpenMobile", true)
        },
        closeMenu() {
            this.$emit("setOpenMobile", false)
        },

        isAuthorizedSublinks(sublink) {
            if (sublink.path && sublink.path.name === 'Vigik3') {
                return this.userIsFrench
            }

            return true
        }
    },
    watch: {
        $route() {
            this.displayNavigationBar()
            this.currentTab = localStorage.getItem("tab")
        },
    },
    created() {
        this.displayNavigationBar()
        this.getNotificationsToast()
    },
    mounted() {
        document.documentElement.style.setProperty(
            "--visible-menu-height",
            `${this.$refs.visibleMenu.offsetHeight}px`
        )
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/global.scss";

@media all and (min-width: 1024px) {
    .mobile-navigation-block {
        display: none;
        position: relative;
    }
    .navigation-block {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .burger-menu {
            display: none;
        }

        .navigation-part-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 44px;

            @media screen and (min-width: 1192px) {
                padding: 0;
                width: max-content;
                min-width: 1192px;
            }
            .navigation-left-part {
                .navigation-list {
                    display: flex;
                    list-style: none;
                    padding: 0;
                    margin: 0 0 0 auto;
                    align-items: center;
                    .list-container {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        .navigation-item {
                            margin: 0 20px 0 20px;
                            transition: margin 200ms;

                            a {
                                font-family: $font_avenir_heavy;
                                font-size: $normal;
                                color: white;
                                text-decoration: none;
                            }

                            a:hover {
                                text-decoration-color: rgba(239, 123, 29, 1);
                                color: $orange-light;
                            }
                        }
                        .isActive a {
                            text-decoration: underline 2px $orange;
                            text-underline-offset: 8px;
                        }

                        .isActive div {
                            text-decoration: underline 2px $orange;
                            text-underline-offset: 8px;
                        }
                    }
                    .logo {
                        min-width: 47px;
                        height: 47px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 400ms;
                        cursor: pointer;

                        img {
                            max-width: 100%;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    // preloading background in order to prevent blinking
                    .preload {
                        display: none;
                    }

                    .navigation-dropdown {

                        .dropdown-toggle {
                            font-family: $font_avenir_heavy;
                            font-size: $normal;
                            color: white;
                            text-decoration: underline 2px
                                rgba(255, 255, 255, 0);
                            text-underline-offset: 8px;
                            transition: text-decoration-color 200ms, color 200ms;

                            cursor: pointer;
                        }

                        .dropdown-toggle::after {
                            margin-left: 10px;
                        }

                        .dropdown-toggle:hover {
                            text-decoration-color: rgba(239, 123, 29, 1);
                            color: $orange-light;
                        }

                        .dropdown-menu {
                            inset: 16px auto auto 0px !important;
                            z-index: 2000;

                            .dropdown-item {
                                color: $blue-neutral;
                                padding: 15px 30px 15px 30px;
                                transition: background-color 200ms,
                                    text-decoration-color 200ms;
                                text-decoration: unset;
                                text-underline-offset: unset;
                            }
                        }

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }

            .navigation-right-part {
                display: flex;
                @media screen and (max-width: 1200px) {
                    width: 30vw;
                }
                .account-button {
                    font-family: $font_avenir_heavy;
                    font-size: $small;
                    width: 170px;
                    height: 60px;
                    margin: 0 0 0 30px;
                    align-items: center;
                    display: flex;
                    color: $white;
                    span {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                }

                .account-button.isActive {
                    color: $orange-light;
                }

                .account-button:hover {
                    color: $orange-light;
                }

                img {
                    margin-left: auto;
                    margin-right: 10px;
                    max-width: 32px;
                }

                .account-button span {
                    margin-right: auto;
                }

                .search-button {
                    width: 60px;
                    height: 60px;
                    margin: 0 0 0 20px;

                    i {
                        font-size: $big;
                        padding-top: 3px;
                    }
                }

                .search-button.isActive {
                    color: $orange-light;
                }

                .search-button:hover {
                    color: $orange-light;
                }

                .notifications-button {
                    width: 60px;
                    height: 60px;
                    margin: 0 0 0 20px;

                    i {
                        font-size: $big;
                        padding-top: 3px;
                    }
                }

                .notifications-button.isActive {
                    color: $orange-light;
                }

                .notifications-button:hover {
                    color: $orange-light;
                }

                .disconnect-button {
                    width: 60px;
                    height: 60px;
                    margin: 0 auto 0 20px;

                    i {
                        font-size: $big;
                        padding-top: 3px;
                    }
                }

                .disconnect-button:hover {
                    color: $orange-light;
                }
            }
        }
    }
}

// @media all and (min-width: 768px) and (max-width: 1024px) {
//     .mobile-navigation-block {
//         display: none;
//     }
//     .navigation-block {
//         width: 100vw;
//         height: 7vh;
//         min-height: 60px;
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         align-content: center;
//         gap: 5px;

//         @media all and (max-width: 768px) {
//             display: none;
//         }
//         // .burger-menu {
//         //     font-size: 2em;
//         //     background-color: unset;
//         //     height: 100%;
//         //     margin-top: 10px;

//         .navigation-part-wrapper {
//             display: flex;
//             .navigation-left-part {
//                 .burger-menu {
//                     font-size: 2em;
//                     background-color: unset;
//                     height: 100%;
//                     margin-top: 10px;

//                     @media all and (max-width: 768px) {
//                         width: 10vw;
//                     }
//                     @media all and (min-width: 768px) {
//                         width: 50vw;
//                     }
//                     display: flex;
//                     flex-direction: row;
//                     justify-content: flex-start;
//                 }

//                 .list-container {
//                     display: none;
//                 }
//                 .list-container.open {
//                     background-color: white;
//                     display: flex;
//                     flex-direction: column;
//                     inset: 16px auto auto 0px !important;
//                     border: 1px solid rgba(0, 0, 0, 0.15);
//                     position: absolute;
//                     top: 40px;
//                     right: 50px;
//                     left: 50px;
//                     z-index: 2;
//                     .navigation-item {
//                         list-style-type: none;
//                         font-family: "Avenir Heavy", serif;
//                         font-size: 12px;
//                         color: $blue-neutral;
//                         padding: 7px 15px 7px 15px;
//                         transition: background-color 200ms,
//                             text-decoration-color 200ms;
//                         i {
//                             font-size: 14px;
//                             margin-right: 15px;
//                             cursor: pointer;
//                         }
//                         a {
//                             font-family: "Avenir Heavy", serif;
//                             font-size: 12px;
//                             text-decoration: none;
//                             color: $blue-neutral;
//                             padding: 7px 15px 7px 15px;
//                         }
//                     }
//                     .dropdown-item:hover {
//                         color: $orange-neutral;
//                         background-color: $grey-lighter;
//                         padding-left: 30px;
//                     }
//                 }
//             }
//             .navigation-right-part {
//                 display: flex;
//                 gap: 5px;
//                 align-items: center;
//                 .btn {
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     width: max-content;
//                 }

//                 .account-button {
//                     width: min-content;
//                 }
//             }

//             .logo {
//                 display: none;
//             }
//             .preload {
//                 display: none;
//             }
//         }
//         img {
//             margin-left: auto;
//             margin-right: 10px;
//             max-width: 32px;
//         }
//     }
//     .dropdown-menu::-webkit-scrollbar {
//         display: none; /* for Chrome, Safari, and Opera */
//     }
//     .dropdown-menu {
//         height: 150px;
//         -ms-overflow-style: none; /* for Internet Explorer, Edge */
//         scrollbar-width: none; /* for Firefox */
//         overflow-y: scroll;
//     }
// }

@media all and (max-width: 1024px) {
    .navigation-block {
        display: none;
    }
    .mobile-navigation-block {
        background: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        color: $white;

        .visible-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em 1.5em;
            border-radius: 0px 0px 2em 2em !important;
            width: 100%;
            height: 100%;
            background-color: $blue-neutral;
            @media all and (min-width: 425px) {
                padding: 1em 2em;
            }
            .left-part-menu {
                width: 3em;
                height: 3em;
                max-width: max-content;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .right-part-menu {
                height: 3em;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;

                .search,
                .notifications,
                .menu {
                    position: relative;
                    width: max-content;
                    width: 3em;
                    height: 3em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $blue-darker;
                    border-radius: 10px;

                    .number-of-notifications {
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        background-color: $orange-neutral;
                        border-radius: 20px;
                        padding: 0 0.5em;
                        animation: pulse 1s infinite;
                    }
                }

                .stroke-wrapper {
                    width: 1.6em;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    gap: 0.3em;

                    .stroke-top,
                    .stroke-middle {
                        border: 0.2em solid $white;
                        border-radius: 100px;
                        width: 100%;
                    }

                    .stroke-bottom {
                        border: 0.2em solid $white;
                        border-radius: 100px;
                        width: 50%;
                    }
                }

                .cross-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5em;
                }

                i {
                    font-size: 1.5em;
                }
            }
        }

        .fade-enter-active,
        .fade-leave-active {
            transition: all 0.3s ease;
        }

        .fade-enter-from,
        .fade-leave-to {
            opacity: 0;
        }

        .unvisible-menu-wrapper {
            width: 100%;
            overflow: auto;
            position: absolute;
            top: var(--visible-menu-height);
            z-index: 100;
            .unvisible-menu {
                background-color: rgba(255, 255, 255, 1);
                height: calc(100vh - var(--visible-menu-height));
                width: 100%;
                padding: 1.5em 1em 0 1em;
                display: flex;
                flex-direction: column;
                gap: 1.5em;
                overflow: scroll;

                @media all and (min-width: 425px) {
                    padding: 1.5em 3em 0 3em;
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    gap: 1.5em;
                    border-bottom: 1px $orange-neutral solid;

                    .navigation-item {
                        font-family: $font_avenir_heavy;
                        font-size: $big;

                        .link {
                            display: block;
                            width: 100%;
                        }
                        .navigation-dropdown {
                            padding-bottom: 1.5rem;
                            .tools {
                                display: flex;
                                width: 100%;
                                justify-content: space-between;

                                .chevron {
                                    transition: all 0.2s ease-in-out;
                                }

                                @media all and (min-width: 425px) {
                                    justify-content: start;
                                    gap: 1em;
                                }

                                h1 {
                                    font-size: $big;
                                    margin: 0;
                                }

                                span {
                                    color: $orange-neutral;
                                }
                            }

                            .content-dropdown {
                                font-size: $normal;
                                font-family: $font-avenir-roman;
                                padding-left: 1em;
                                padding-top: 1em;
                                padding-bottom: 1em;
                                display: flex;
                                flex-direction: column;
                                gap: 1em;
                                transition: all 0.2s ease-in-out;

                                @media all and (min-width: 425px) {
                                    padding-left: 3em;
                                }
                            }
                        }
                    }
                }

                .account {
                    display: flex;
                    gap: 0.5em;
                    align-items: center;

                    h1 {
                        font-family: $font_avenir_heavy;
                        font-size: $big;
                        margin: 0;
                    }
                    span {
                        font-size: $big;
                        color: $orange-neutral;
                    }
                }

                .logout {
                    display: flex;
                    gap: 0.5em;
                    align-items: center;

                    h1 {
                        font-family: $font_avenir_heavy;
                        font-size: $big;
                        margin: 0;
                    }
                    span {
                        font-size: $big;
                        color: $orange-neutral;
                    }
                }

                a,
                span,
                h1 {
                    text-decoration: none;
                    color: $blue-neutral;
                }
            }
        }
    }
}

// .fade-in {
//     animation: fadeIn 0.2s;
//     animation-fill-mode: forwards;
// }

// .fade-out {
//     animation: fadeOut 0.2s;
//     animation-fill-mode: forwards;
// }
// .stroke-disappears {
//     animation: slideOutLeft 0.5s;
//     animation-fill-mode: forwards;
// }
// .stroke-appears {
//     animation: slideInLeft 0.5s;
//     animation-fill-mode: forwards;
// }

// .delay-100 {
//     animation-delay: 0.1s;
// }

// .delay-500 {
//     animation-delay: 0.5s;
// }
</style>
